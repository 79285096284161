import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import {api, paths} from "../../services/api";
import Tile from "../control/Tile"
import Modal from "../Modal";

class NewTemplateModal extends Component {
	constructor(){
		super();

		this.state = {
			isLoading : false
		};

		this.labelInput = createRef();


		this.newTemplate = this.newTemplate.bind(this);
		this.editTemplate = this.editTemplate.bind(this);
		this.formChanged = this.formChanged.bind(this);

	}

	componentDidMount(){
		this.labelInput.current.focus();
	}

	async editTemplate(){
		const {template, onEdited, onClose} = this.props;
		const {label, isLoading} = this.state;

		if(isLoading) return;

		this.setState({isLoading : true});

		const response = await api.post(paths.template, {
			template: template.id,
			label
		});

		if(response.status === "ok"){
			onEdited(label);
		} else{
			swal.fire({
				type : "error",
				text : response.message
			});

			this.setState({isLoading : false})
		}
	}

	async newTemplate(){
		const {onTemplateAdded, onClose} = this.props;
		const {label, isLoading} = this.state;

		if(isLoading) return;
		this.setState({isLoading : true});


		const response = await api.post(paths.templates, {
			label
		});

		if(response.status === "ok"){
			onTemplateAdded(response.data);
			onClose();
		} else {
			swal.fire({
				type : "error",
				text : response.message
			});

			this.setState({isLoading : false});
		}
	}

	formChanged(event){
		this.setState({
			[event.target.name] : event.target.value
		});
	}

	render(){
		const {onClose, template} = this.props;
		const {isLoading} = this.state;


		return (
			<Modal
				isOpen={true}
				closeable={true}
				onClose={onClose}
				onSubmit={template ? this.editTemplate : this.newTemplate}
				footer={(
					<button
						type="submit"
						disabled={isLoading}
						className={`button is-primary ${isLoading && "is-loading"}`}
					>
						{template ? "Edit" : "Create"}
					</button>
				)}
				label={`${template ? "Edit" : "Create"} Template`}
			>
				<div class="field">
					<div class="column">
						<div class="columns">
							<div class="column is-4">
								<label class="label has-help">Label</label>
								<small>The name of your template</small>
							</div>

							<div class="column">
								<div class="control">
									<input defaultValue={template.label} className="input" ref={this.labelInput} placeholder="Template" required pattern=".{2,}" name="label" type="text" onChange={this.formChanged}/>
							</div>
							</div>
						</div>
					</div>
				</div>

			</Modal>
		)
	}
}

NewTemplateModal.propTypes = {
	onClose : PropTypes.func.isRequired,
	environment : PropTypes.object,
	onTemplateAdded : PropTypes.func.isRequired,
	onDeleted : PropTypes.func,
	template: PropTypes.object,
}

NewTemplateModal.defaultProps = {
	onClose : () => {},
	onDeleted : () => {},
	template: false
};

export default NewTemplateModal