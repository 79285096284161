import React, {Fragment} from "react";

import Tabs from "../components/templating/Tabs";
import Section from "../components/templating/Section";
import ButtonBar from "../components/templating/ButtonBar";
import Slider from "../components/templating/Slider";
import IconButton from "../components/templating/IconButton";
import TemplateModal from "../components/templating/TemplateModal";
import StatusButton from "../components/templating/StatusButton";
import Select from "../components/templating/Select";
import Checkbox from "../components/templating/Checkbox";

import {Container, Content, Columns, Column} from "../components/templating/Layout";
import VerticalController from "../components/templating/VerticalController";


export const parseElements = (elements) => Array.isArray(elements) ? elements.map(parseElement) : parseElement(elements);

export const parseModals = (modals) => {
  return Object.keys(modals).map((modalName) => {
      console.log("template modal loaded", modalName, modals)
      return (
          <TemplateModal name={modalName} modal={modals[modalName]} />
      );
  });
};

const renderButtonToggleBar = (element) => {
    return (
        <ButtonBar
            state = {element.state}
            buttons = {element.buttons}
            action = {element.action}
            rightAlign = {element.rightAlign}
            size = {element.size}
            fromState = {element.fromState}
            rounded = {element.rounded}
            color={element.color}
        />
    )
};

const renderCheckbox = (element) => {
    return (
        <Checkbox
            state = {element.state}
            action = {element.action}
            data = {element.data}
        />
    )
}

const renderSlider = (element) => {
    return (
        <Slider
            state = {element.state}
            action = {element.action}
            min = {element.min}
            max = {element.max}
            data = {element.data}
            step = {element.step}
        />
    )
};


const renderSelect = (element) => {
    return (
        <Select
            state = {element.state}
            action = {element.action}
            options = {element.options}
            data = {element.data}
        />
    )
};

const renderTabs = (element) => {
    if(!element.content) throw new Error("renderTabs() does not have a content element");

    return (
        <Tabs
            isSecondary={element.subtype === "secondary"}
            inTab={element.inTab}
            localState={element["local-state"]}
            tabs={
                element.content
                    .filter(tab => tab.type === "tab")
            }
        />
    );
};

const renderSection = (section) => {
    console.log("rendering section",section)
    return (
        <Section
            label={section.label}
            content={section.content}
            buttons={section.buttons}
        />
    );
};


const renderStatusButton = (button) => {
    return (
        <StatusButton
            labels={button.statusLabels}
            statusKey={button.statusKey}
        />
    )
}

const renderIconButton = (props) => (<IconButton {...props}/>);
const renderVerticalController = (props) => (<VerticalController {...props} />);
const renderContent = (props) => (<Content {...props} />)
const renderContainer = (props) => (<Container {...props}/>);
const renderColumns = (props) => (<Columns {...props}/>);
const renderColumn = (props) => (<Column {...props}/>);



const renderMethods = {
    "tabs" : renderTabs,
    "content": renderContent,
    "section" : renderSection,
    "button-toggle-bar" : renderButtonToggleBar,
    "icon-button" : renderIconButton,
    "slider": renderSlider,
    "status-button": renderStatusButton,
    "select": renderSelect,
    "checkbox": renderCheckbox,
    "vertical-controller": renderVerticalController,
    "container": renderContainer,
    "columns" : renderColumns,
    "column" : renderColumn
};

const parseElement = (element) => {
    const type = element.type;
    if(renderMethods[type]) return renderMethods[type](element);
    else console.log(`viot: Templating type '${type}' not found`);
};

const renderTextField = (field, onChange) => {
    return (
      <input
        className="input"
        placeholder={field.placeholder}
        required
        pattern={field.pattern}
        name={field.name}
        title={field.title}
        type="text"
        minlength={field.minLength}
        maxlength={field.maxLength}
        onChange={onChange}
      />
    );
}

const renderFieldMethods = {
    "text": renderTextField
}

export const renderField = (field, onChange) => {
    const type = field.type;
    if(renderFieldMethods[type]) return renderFieldMethods[type](field, onChange)
    else console.log(`viot: Templating field type '${type}' not found`)
}