import React from "react";
import PropTypes from "prop-types";
import { Heading } from "react-bulma-components";
import { Link } from "react-router-dom";


class ViewCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			starHovered: false,
			isLoading: props.backgroundImage
		};

		this.handleStarHover = this.handleStarHover.bind(this);
	}

	componentDidMount() {
		const { backgroundImage } = this.props;

		if (backgroundImage) {
			const image = new Image();
			image.src = backgroundImage;
			image.onload = () => this.setState({ isLoading: false });
		}
	}

	handleStarHover() {
		const { starHovered } = this.state;

		this.setState({
			starHovered: !starHovered
		});
	}

	render() {
		const {
			text,
			backgroundImage,
			href,
			onClick,
			isStarred,
			onStarred,
			isSmall,
			isSelected,
			statusTooltip,
			statusClass,
			isDisabled
		} = this.props;

		const {
			starHovered,
			isLoading
		} = this.state;

		const LinkElement = href ? Link : "a";
		const containerClass = `control-tile-container ${isLoading ? "is-loading" : ""} ${isSelected ? "selected" : ""} ${isDisabled ? "is-disabled" : ""}`;
		const starClass = isStarred || starHovered ? "fas fa-star" : "far fa-star";
		const starContainerClass = `control-star-container ${isStarred ? "starred" : ""}`;
		const background = backgroundImage ? `url(${backgroundImage})` : "none";

		return (
			<div className={containerClass}>
				{onStarred && (
					<div className={starContainerClass}>
						<a
							role="button"
							onClick={onStarred}
							onMouseEnter={this.handleStarHover}
							onMouseLeave={this.handleStarHover}
						>
							<span className="icon">
								<i className={starClass} />
							</span>
						</a>
					</div>
				)}
				<LinkElement onClick={isDisabled ? undefined : onClick} to={isDisabled ? undefined : href}>
					<article
						className="article notification is-primary control-tile"
						style={{
							backgroundImage: !isLoading ? background : "none",
							...styles.tile,
							paddingTop: isSmall ? "15%" : "45%"
							
						}}
					>
						{statusTooltip && (
							<div className="control-status-container">
								<div data-tooltip={statusTooltip} className={`control-status has-tooltip-left ${statusClass}`}></div>
							</div>
						)}
						{text && text.length > 0 && (
							<div style={styles.content}>
								<div style={styles.textContainer}>
									{!isLoading && <Heading style={styles.heading} subtitle size={3} centered>{text}</Heading>}
								</div>
							</div>
						)}
					</article>
				</LinkElement>
			</div>
		);
	}
}


const styles = {
  tile: {
    backgroundPosition: "45%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    opacity: 1,
    backgroundColor: "transparent",
    margin: 0,
    borderRadius: 10,
    overflow: "hidden",
    backdropFilter: "blur(6px)",
  },

  content: {
    backgroundColor: "rgba(0,0,0,.4)",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 2,
  },

  textContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "100%",
  },

  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 10,
  },

  title: {
    color: "white",
    paddingBottom: 0,
    opacity: 1,
    transform: "translateY(5px)",
    transition: "all 0.3s",
    transitionDelay: "0.1s",
    fontSize: "30px",
    fontWeight: 400,
  },

  heading: {
    fontWeight: 400
  },
};

ViewCard.propTypes = {
	backgroundImage: PropTypes.string.isRequired,
	text: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func,
	isStarred: PropTypes.bool,
	isSmall: PropTypes.bool,
	onStarred: PropTypes.func,
	isSelected: PropTypes.bool,

	statusTooltip: PropTypes.string,
	statusClass: PropTypes.string,
	isDisabled : PropTypes.bool,

};

ViewCard.defaultProps = {
	text: "",
	href: "",
	onClick: false,
	isStarred: false,
	onStarred: false,
	isSelected: false,
	isSmall : false,
	isDisabled : false,
	statusTooltip : false,
	statusClass: ""
};

export default ViewCard;
