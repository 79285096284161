import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import { deviceContext } from "../../viotContext";
import { parseElements } from "../../services/TemplateEngine";

class Section extends Component {
  constructor(props) {
	super(props);
	
	this.buttonClicked = this.buttonClicked.bind(this);
  }

  async buttonClicked(device, button) {
	  console.log("save profile", button, "sending action")
    await device.sendAction({
      command: button.action,
      value: { value: button.value, data: button.data },
    });
  }

  render() {
    const { label, buttons = [], content } = this.props;

    return (
      <div className="control-section">
        <div className="level">
          <div className="level-left">
            <h4 className="subtitle">{label}</h4>
          </div>
		  <deviceContext.Consumer>
				{device => {
					if(!device) device = {state : {}};
					return (
						<div className="level-right">
						{buttons.filter((button) => {
              console.log(button.visibleState);
              return !button.visibleState || device.state[button.visibleState];
            }).map((button) => (
							<a
							role="button"
							onClick={() => this.buttonClicked(device, button)}
							class="has-text-weight-light has-text-whiter"
							>
							{button.label}
							</a>
						))}
						</div>
					);
				}}
			</deviceContext.Consumer>
         
        </div>
        <div className="columns">
          <div className="column">{content.map(parseElements)}</div>
        </div>
      </div>
    );
  }
}

Section.propTypes = {
    label : PropTypes.string.isRequired,
    content : PropTypes.array.isRequired
};

export default Section;