import React, {Component} from "react";
import PropTypes from "prop-types";
import { Heading } from "react-bulma-components";
import Tile from "../components/control/Tile"
import { Animated } from "react-animated-css";
class Modal extends Component{
	constructor(props){
		super();

		this.state = {
			isOpen : props.isOpen
		};

		this.closeModal = this.closeModal.bind(this);
	}

	componentDidUpdate(oldProps){

		const {isOpen} = this.props;
		if(oldProps.isOpen !== isOpen) this.setState({isOpen : isOpen});
	}

	closeModal(){
		this.setState({isOpen : false});
		this.props.onClose();
	}


	render(){
		const {children, closeable, label, footer, footerLeft, footerRight, onSubmit} = this.props;
		const {isOpen} = this.state;

		return isOpen ? (
				
			<div className="modal is-active">
				<div class="modal-background"></div>

			<Animated
				animationIn="fadeIn"
				animationInDuration={250}>
				<div class="modal-card">
					<header class="modal-card-head">
						<p class="modal-card-title">{label}</p>
						{closeable && (
							<button class="delete" aria-label="close" onClick={this.closeModal}></button>
						)}
					</header>

					<form onSubmit={(e) => {
						e.preventDefault();
						onSubmit(e);
					}}>
						<section class="modal-card-body">
							<input type="submit" style={{display: "none"}}/>
							{children}
						</section>
				
						<footer class="modal-card-foot">
								<div class="level">
									<div class="level-left">
											{footerLeft}
										</div>
	
	
										<div class="level-right">
												{footer || footerRight}
										</div>
			
								</div>
	
							
						</footer>
					</form>

				</div>
				</Animated>

			  </div>
		) : (<div/>)
	}
}

Modal.propTypes = {
	children : PropTypes.object,
	onClose : PropTypes.func.isRequired,
	footer : PropTypes.element,
	footerLeft : PropTypes.element,
	footerRight : PropTypes.element,
	label : PropTypes.string,
	onSubmit : PropTypes.func,
	closeable : PropTypes.bool
}

Modal.defaultProps = {
	onClose : () => {}
}

export default Modal;