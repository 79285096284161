import
	React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Button from "./Button";

class StatusButton extends Component{
	constructor(props){
		super(props);

		const {labels} = this.props;
		this.state = {
			label : labels.default || "Error"
		}
	}

	render() {
		const {
			size,
			color,
			rounded
		} = this.props;

		const {label} = this.state;

		return (
			<Fragment>
				<Button
					rounded={rounded}
					size={size}
					color={color}
					label={label}
				/>
			</Fragment>
		);
	}
}

StatusButton.propTypes = {
	label: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	size: PropTypes.string,
	color: PropTypes.bool,
	rounded: PropTypes.bool
};

StatusButton.defaultProps = {
	size: "medium",
	color: "standard"
};

export default StatusButton;
