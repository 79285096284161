import React, { Component } from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Home from "./pages/Home";
import Space from "./pages/Space";
import Template from "./pages/Template";
import Templates from "./pages/Templates";
import Devices from "./pages/Devices";
import User from "./pages/User";
import {userContext} from "../viotContext";
import Device from "./pages/Device";

const AuthenticatedRoute = ({ component : Component, user, power, ...rest }) => {
	return (
		<Route {...rest} render={props => {
			return (
				<userContext.Consumer>
					{user => user.isAuthenticated && (!power || power <= user.details.power) ?
						(<Component {...props} />) : (<Redirect to="/"/>)
					}
				</userContext.Consumer>
			);
		}}/>
	);
};

class Main extends Component{
	render(){
		return (
			<main>

				<Route exact path="/" component={Home}/>
				<AuthenticatedRoute exact path="/user" component={User} />

				<Route exact path="/template" component={Template} />
				<Route exact path="/templates" component={Templates} />

				<AuthenticatedRoute exact path="/space/:spaceId/:space/:deviceId/:deviceName" component={Device} />
				<AuthenticatedRoute exact path="/space/:spaceId" component={Space} />
				<AuthenticatedRoute exact path="/space/:spaceId/:space" component={Space} />
				<AuthenticatedRoute exact path="/devices" component={Devices} />
			</main>
		)
	}
}

export default Main
