export const formatSpaceUrl = (id, label) => {
    const labelStr = label
        .replace(/\d*/g, "")
        .trim()
        .replace(/\s/g, "-")
        .toLowerCase()
        .match(/([a-z-]*)/g)
        .join("");

    return `/space/${id}/${labelStr}`;
}