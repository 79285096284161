import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import {api, paths} from "../../services/api";
import Tile from "../control/Tile"
import Modal from "../Modal";

class NewEnvironmentModal extends Component {
    constructor(){
        super();

        this.state={
            isLoading : false
        }
        this.labelInput = createRef();


        this.newEnvironment = this.newEnvironment.bind(this);
        this.editEnvironment = this.editEnvironment.bind(this);
        this.formChanged = this.formChanged.bind(this);

    }

    componentDidMount(){
        this.labelInput.current.focus();
    }

    async editEnvironment(){
        const {environment, onEdited, onClose} = this.props;
        const {label, isLoading} = this.state;

        if(isLoading) return;
        this.setState({isLoading : true});
        
        const response = await api.post(paths.environment, {
            environment : environment.id,
            label
        });

        if(response.status === "ok"){
            onEdited({...environment, ...response.data});
            onClose();
        } else{
            swal.fire({
                type : "error",
                text : response.message
            });

            this.setState({isLoading : false})
        }
    }

    async newEnvironment(){
        const {onEnvironmentAdded, onClose} = this.props;
        const {label, isLoading} = this.state;

        if(isLoading) return;
        this.setState({isLoading : true});

        const response = await api.post(paths.newEnvironment, {
            label,
        });

        if(response.status === "ok"){
            onEnvironmentAdded(response.data);
            onClose();
        } else {
            swal.fire({
                type : "error",
                text : response.message
            });

            this.setState({isLoading : false});
        }
    }

    formChanged(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    render(){
        const {onClose, environment} = this.props;
        const {isLoading} = this.state;

        return (
            <Modal
                isOpen={true}
                closeable={true}
                onClose={onClose}
                onSubmit={environment ? this.editEnvironment : this.newEnvironment}
                footer={(
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`button is-primary ${isLoading && "is-loading"}`}
                    >
                        {environment ? "Edit" : "Create"}
                    </button>
                )}
                label={`${environment ? "Edit" : "Create"} Environment`}
            >
                <div class="field">
                    <div class="column">
                        <div class="columns">
                            <div class="column is-4">
                                <label class="label has-help">Label</label>
                                <small>The name of your environment</small>
                            </div>

                            <div class="column">
                                <div class="control">
                                    <input defaultValue={environment.label} className="input" ref={this.labelInput} placeholder="Lounge" required pattern=".{2,}" name="label" type="text" onChange={this.formChanged}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        )
    }
}

NewEnvironmentModal.propTypes = {
    onClose : PropTypes.func.isRequired,
    environment : PropTypes.object,
    onEnvironmentAdded : PropTypes.func.isRequired,
    onDeleted : PropTypes.func,
}

NewEnvironmentModal.defaultProps = {
    onClose : () => {},
    onDeleted : () => {},
    environment : false
}

export default NewEnvironmentModal