import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {deviceContext} from "../../viotContext";
import { parseElements } from "../../services/TemplateEngine";
import Button from "./Button";

class Select extends Component{
	constructor(props){
		super(props);



		this.selectChanged = this.selectChanged.bind(this);
	}

	async selectChanged(device, event){
		const {action, data} = this.props;

		const value = event.target.value;
		console.log(this.props)
		console.log(device.state)
		console.log("changed to: ", event.target.value);
		console.log("value", this.props.options)

		await device.sendAction({command : action, value: {value, data}});
		// const {action} = this.props;
		// await device.sendAction({command : action, value: {value: button.value, data: button.data}});
		// this.setState({activeButton : button});
	}

	render(){
		const {options, centered, state} = this.props;

		return (
			<Fragment>
				<div className="columns">
					<deviceContext.Consumer>
						{device => {
							// console.log("Device state is: ", device.state.state)
							return (
								<div className={`column controls ${centered ? "has-text-centered" : ""}`}>
									<select
										className="control select"
										onChange={(event) => this.selectChanged(device, event)}
										value={device.state[state]}
									>
										{options.map(option => ((
											<option>{option.label}</option>
										)))}
									</select>
									{/*{buttons.map(button => {*/}
									{/*	return (*/}
									{/*		<select*/}
									{/*			label={button.label}*/}
									{/*			active={!button.toggleable && device.state[state] === button.value}*/}
									{/*			size={size}*/}
									{/*			color={button.color || color}button*/}
									{/*			rounded={button.rounded || rounded}*/}

									{/*			toggleable={button.toggleable}*/}
									{/*			action={button.action}*/}
									{/*			toggled={device.state[button.state]}*/}
									{/*			toggleOnColor={button.toggleOnColor ? button.toggleOnColor : "success"}*/}
									{/*			toggleOffColor={button.toggleOffColor ? button.toggleOffColor : "failure"}*/}

									{/*			onClick={async () => {*/}
									{/*				console.log("our toggle is:", typeof button.toggleable)*/}
									{/*				if(button.toggleable){*/}
									{/*					console.log("is toggle")*/}
									{/*					await this.toggleButtonClicked(device, button);*/}
									{/*					return*/}
									{/*				}*/}
									{/*				console.log("calling ubtton clicked");*/}

									{/*				await this.buttonClicked(device, button);*/}
									{/*			}}*/}
									{/*		/>*/}
									{/*	);*/}
									{/*})}*/}
								</div>
							);
						}}
					</deviceContext.Consumer>
				</div>
			</Fragment>
		)
	}
}

Select.propTypes = {
	buttons : PropTypes.array.isRequired,
	action : PropTypes.string.isRequired,
	size : PropTypes.string.isRequired,
	color : PropTypes.string.isRequired
};

export default Select;