import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import {api, paths} from "../../services/api";
import Tile from "../control/Tile"
import Modal from "../Modal";
import PageText from "../elements/PageText";

class UnlinkDevice extends Component {
	constructor(props){
		super(props);

		this.state = {
            isLoading: false,
            selectedDevice : props.devices[0].id,
            devices: []
        };
        
        this.unlinkDevice = this.unlinkDevice.bind(this);
        this.deviceChanged = this.deviceChanged.bind(this); 
	}
    
    deviceChanged(event){
        this.setState({selectedDevice : event.target.value});
    }

	async unlinkDevice(){
        const {isLoading, selectedDevice} = this.state;
        const {onDeviceUnlinked} = this.props;

        if(isLoading) return false;
        this.setState({ isLoading : true});
        
        const spaceResult = await api.post(paths.deviceUnlink, {device: selectedDevice});
        if(spaceResult.status === "ok"){
            onDeviceUnlinked(selectedDevice);
        } else {
            swal.fire({
                type : "error",
                text : spaceResult.message
            });
        }

        this.setState({ isLoading : false});

    }
    

	formChanged(event){
		this.setState({
			[event.target.name] : event.target.value
		});
	}

	render(){
		const { onClose, devices, spaceName } = this.props;
		const { isLoading } = this.state;

		return (
			<Modal
				isOpen
				closeable
				onClose={onClose}
				onSubmit={this.linkDevice}
				footer={devices.length > 0 && (
					<a
						onClick={this.unlinkDevice}
						className={`button is-primary ${isLoading && "is-loading"}`}
					>
						Unlink
					</a>
				)}
				label={`Unlink device from ${spaceName}`}
			>
                {devices.length > 0 && (
                    <div className="field">
                        <div className="column">
                            <div className="columns">
                                <div className="column is-4">
                                    <label className="label has-help">Device name</label>
                                    <small>The device you wish to unlink</small>
                                </div>

                                <div className="column">
                                    <div className="control">
                                        <select className="select" name="device" onChange={this.deviceChanged}>
                                        
                                                {devices.map((device) => (
                                                    <option value={device.id} key={device.id}>{device.label} ({device.name})</option>
                                                ))}
                                        
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {devices.length <= 0 && (
                    <PageText modal>You have no devices to unlink</PageText>  
                )}

			
			</Modal>
		)
	}
}

UnlinkDevice.propTypes = {
    onClose: PropTypes.func.isRequired,
    spaceName : PropTypes.string.isRequired,
    onDeviceUnlinked : PropTypes.func.isRequired,
};

UnlinkDevice.defaultProps = {
	onClose : () => {}
};

export default UnlinkDevice;
