import React, {Component} from "react";
import PropTypes from "prop-types";
import Tile from "./control/Tile";

class DeviceTile extends Component{
    render(){
        const {device, spaceId, spaceName, onStarred} = this.props;
        return (
            <Tile
                text={device.label}
                href={`/space/${spaceId}/${spaceName}/${device.id}/${device.name}`}
                backgroundImage={device.image}
                statusClass={device.status}
                statusTooltip={`Device is ${device.status || "unknown"}`}
                // isDisabled
                onStarred={onStarred}

                isStarred={device.starred}

            />
        )
    }
}

DeviceTile.propTypes = {
    device : PropTypes.object.isRequired,
    spaceId : PropTypes.string.isRequired,
    spaceName : PropTypes.string.isRequired,
    onStarred : PropTypes.func,
};

DeviceTile.defaultProps = {
    label : "Unknown",
    onStarred : null
};

export default DeviceTile;