import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import { deviceContext } from "../../viotContext";
import { api, paths } from "../../services/api";
import Tile from "../control/Tile";
import Modal from "../Modal";
import {renderField} from "../../services/TemplateEngine";

class TemplateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {}
    }

    this.submitForm = this.submitForm.bind(this);
    this.formChanged = this.formChanged.bind(this);
  }

  async submitForm(device) {
    const { modal } = this.props;
    const { form } = this.state;

		await device.sendAction({ command: modal.action, value: { data: form } });


    device.closeModal();
  }

  formChanged(event) {
    const {form} = this.state;
    form[event.target.name] = event.target.value;

    this.setState({
      form
    });
  }


  render() {
    const { modal, name } = this.props;

    return (
      <deviceContext.Consumer>
        {device => {
          if (!device) device = { state: {}, isModalActive: () => false };

          return (
            <Modal
              isOpen={device.isModalActive(name)}
              closeable={true}
              onClose={device.closeModal}
              onSubmit={() => this.submitForm(device)}
              footer={
                modal.submitLabel && (
                  <button type="submit" className={`button is-primary`}>
                    {modal.submitLabel}
                  </button>
                )
              }
              label={modal.title}
            >
              {modal.content.map((field) => (
                <div class="field">
                  <div class="column">
                    <div class="columns">
                      <div class="column is-4">
                        <label class="label has-help">{field.label}</label>
                        <small>{field.description}</small>
                      </div>

                      <div class="column">
                        <div class="control">
                          {renderField(field, this.formChanged)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Modal>
          );
        }}
            </deviceContext.Consumer>
    );
  }
}

export default TemplateModal;
