import
	React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {deviceContext} from "../../viotContext";
import Button from "./Button";

class IconButton extends Component{
	constructor(props){
        super(props);
        
        this.onClick = this.onClick.bind(this);
    }
    
    async onClick(device){
        const {action, value, data} = this.props;

        await device.sendAction({command : action, value: {value, data}})
    }

	render() {
		const {
			size,
			color,
            rounded,
            icon,
            label,
            "icon-color" : iconColor
		} = this.props;


		return (
			<Fragment>
                <deviceContext.Consumer>
                    {(device) => (
                        <Button
                            rounded={rounded}
                            size={size}
                            color={color}
                            onClick={() => this.onClick(device)}
                            className={`
                                is-icon
                                ${label ? "has-label" : ""}
                                ${iconColor ? `has-text-${iconColor}` : ""}
                            `}
                        >
                            {label ? label : <i class={`fa fa-${icon}`}></i>}
                        </Button>
                    )}
                </deviceContext.Consumer>

			</Fragment>
		);
	}
}

IconButton.propTypes = {
	label: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	size: PropTypes.string,
	color: PropTypes.bool,
	rounded: PropTypes.bool
};

IconButton.defaultProps = {
	size: "medium",
	color: "standard"
};

export default IconButton;
