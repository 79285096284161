import React, {Fragment} from 'react';

import { Container, Columns, Hero, Heading } from "react-bulma-components";
import MaterialTheme from 'react-sortable-tree-theme-material-ui';
import GenericPage from "./Generic";
import GenericHeader from "../elements/headers/GenericHeader";
import {parseElements} from "../../services/TemplateEngine";
import testJson from "../../services/template-test";
import ButtonBar from "../templating/ButtonBar";

class Template extends GenericPage{
    constructor(props){
        super(props);

        // this.elements = parseElements(testJson.control);

    }

    render() {
        const landscape = testJson.orientation === "landscape";

        return (
            <Fragment>
                <GenericHeader title="Devices" buttons={[{label: "Template"}]}/>
                <Container className="is-centered">
                    <div className="columns is-centered">
                        <div className={`column ${landscape ? "is-9-fullhd is-11-desktop" : "is-4-fullhd is-6-desktop"} is-12`}>
                            <div className="box control-panel">
                                <div className="columns">
                                  <div className="column">
                                      <div className="columns">
                                          <div className="column">
                                              <h1 className="timer">
                                                  00:00
                                              </h1>
                                          </div>
                                      </div>

                                      <div className="columns">
                                          <div className="column">
                                              <div className="status-blocks">
                                                  <div className="status-block danger is-active">
                                                     Stopped
                                                  </div>
                                                  <div className="status-block success">
                                                      Running
                                                </div>
                                              </div>
                                          </div>
                                      </div>




                                              <ButtonBar
                                                  buttons={[
                                                      {
                                                          label: "+1 minute"
                                                      },
                                                      {
                                                          label: "5 minutes"
                                                      },
                                                      {
                                                          label: "10 minutes"
                                                      }
                                                  ]}
                                                  centered

                                              size="medium"/>


                                      <div className="columns">
                                          <div className="column">


                                          </div>
                                      </div>
                                    {/*{this.elements}*/}
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default Template