import React, {Component, Fragment, createRef} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import {api, paths} from "../../services/api";
import Tile from "../control/Tile"
import Modal from "../Modal";
import {Animated} from "react-animated-css";
import TemplateEditorItems from "../TemplateEditorItems";
import {parseElements} from "../../services/TemplateEngine";
import {deviceContext} from "../../viotContext";

class NewDeviceModal extends Component {
	constructor(props){
		super(props);

		const {template} = props;

		this.state = {
			isLoading: false,
			template: {label: "Loading..."},
			templateJson: "",
			lastDeviceMessage: "",
			hasChanges: false,
			error: false
		};


		this.formChanged = this.formChanged.bind(this);
		this.reRender = this.reRender.bind(this);
		this.saveTemplate = this.saveTemplate.bind(this);

	}

	async componentDidMount(){
		const {template, onClose} = this.props;
		const templates = await api.get(paths.template, {template: template.id});
		if(templates.status === "ok"){
			this.setState({template: templates.data, templateJson: JSON.stringify(templates.data.template, null, 4)});
		} else{
			swal.fire({type: "error", text: "Failed to fetch template"});
			onClose();
		}
	}

	async reRender(){
		const {template} = this.props;
		const {templateJson = JSON.stringify(template.template)} = this.state;

		try{
			const elements = JSON.parse(templateJson);
			// await this.setState({template: defaultTemplate});

			this.setState({
				template: {label: template.label, id: template.id, template: elements},
				hasChanges: false,
				error: false
			})
		} catch(e){
			this.setState({
				error: e.toString()
			});
		}
	}

	async saveTemplate(){
		const {onClose} = this.props;
		const {template} = this.state;

		this.setState({isLoading: true});

		const result = await api.post(paths.templateJson, {template: template.id, json: JSON.stringify(template.template)});
		if(result.status === "ok"){
			await swal.fire({title: "Success", text: "Template saved successfully!", type: "success"});
			onClose();
		} else
			await swal.fire({title: "An error occurred", text: result.message, type: "error"});


		this.setState({isLoading: false});
	}

	formChanged(event){
		this.setState({
			hasChanges: true,
			[event.target.name] : event.target.value
		});
	}

	render(){
		const { onClose, device, onDeleted } = this.props;
		const { isLoading, deviceImages, error, templateJson, hasChanges, template, lastAction } = this.state;

		const deviceCommunicator = {
			sendAction : (action) => {
				this.setState({
					lastAction: action
				})
			},
			state : {}
		};

		return (
			<div className="modal is-active">
				<div className="modal-background"/>


					<Animated
						animationIn="fadeIn"
						animationInDuration={250}>
						<div className="modal-card template-editor is-large" style={{overflowY: "hidden"}}>
							<header className="modal-card-head">
								<p className="modal-card-title">Template editor for {template.label}</p>
								{error || lastAction ? <pre onChange={this.formChanged} style={{
									marginRight: 30,
									padding: 5
								}}>{error ? `Error: ${error}` : lastAction ? `Last action: ${JSON.stringify(lastAction)}` : undefined}</pre> : undefined}
								<button onClick={this.reRender} disabled={!hasChanges} className="button is-info"
										style={{marginRight: 10}}>Render
								</button>
								<button
									onClick={this.saveTemplate}
									disabled={hasChanges || isLoading}
									className={`button ${isLoading ? "is-loading" : ""} ${hasChanges ? "is-danger" : "is-success"}`}
									style={{marginRight: 30}}>
									Save
								</button>
								<button className="delete" aria-label="close" onClick={onClose}/>
							</header>

							<form onSubmit={(e) => {
								e.preventDefault();
								// onSubmit(e);
							}}>
								<section className="modal-card-body" style={{overflow: "hidden"}}>
									<input type="submit" style={{display: "none"}}/>
									<div className="field">
										<div className="column">
											<div className="columns">
												{template && template.template ? (
													<Fragment>
														<div className="column has-height-auto is-2 template-editor-elements">
															<TemplateEditorItems tree={template.template.control}/>

														</div>

														<div className="column is-6" style={{padding: 0, position: "relative"}}>
															<div className="columns is-centered">
																<div className={`column is-12`}>
																	<Animated
																		animationIn="fadeIn"
																		animationInDelay={100}
																		animationInDuration={250}
																	>
																		<deviceContext.Provider value={deviceCommunicator}>
																			{parseElements(template.template.control)}
																		</deviceContext.Provider>
																	</Animated>
																</div>
															</div>

														</div>

														<div className="column is-4" style={{padding: 0}}>
															<textarea name="templateJson" onChange={this.formChanged}
																	  className="json-area">
																{templateJson}
															</textarea>
														</div>
													</Fragment>
													) : (<div/>)}
											</div>
										</div>
									</div>
								</section>
							</form>

						</div>
					</Animated>
				</div>


		)
	}
}

NewDeviceModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDeleted : PropTypes.func,
	device : PropTypes.object
};

NewDeviceModal.defaultProps = {
	onClose : () => {},
	device : {},
	onDeleted : () => {}
};

export default NewDeviceModal;
