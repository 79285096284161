import React, {Fragment} from 'react';

import { Container, Columns, Hero, Heading } from "react-bulma-components";

import GenericPage from "./Generic";
import GenericHeader from "../elements/headers/GenericHeader";
import {parseElements} from "../../services/TemplateEngine";
import testJson from "../../services/template-test";
import PageText from "../elements/PageText";
import Link from "../elements/Link";
import LinkClientModal from "../modals/LinkClientModal"

class User extends GenericPage{
	constructor(props){
		super(props);

		this.elements = parseElements(testJson.control);

		this.state = {
			linkClientModal : false
		};

		this.onLinkClientClicked = this.onLinkClientClicked.bind(this);
		this.onClientLinked = this.onClientLinked.bind(this);
	}

	onLinkClientClicked(){
		this.setState({
			linkClientModal : true
		});
	}

	onClientLinked(){
	}


	render() {
		const {linkClientModal} = this.state;
		return (
			<Fragment>
				<GenericHeader title="Your Account" buttons={[{label: "User"}]}/>
				<Container className="is-centered">

					<div className="columns is-centered">


						<div className="column is-9-tablet ">

							<h1 className="subtitle is-3">Account Settings</h1>
							<div class="columns">
								<div class="column">
							<div className="card">
								<header className="card-header">
									<p className="card-header-title">
										Linked clients
									</p>
									<a role="button" className="card-header-icon has-text-weight-light has-text-white">Link a client</a>

								</header>
								<div className="card-content">
									<PageText modal>You have no linked clients. <Link className="is-link" onClick={this.onLinkClientClicked}>Add one</Link></PageText>
								</div>
							</div>
								</div>
							</div>
				



						</div>
					</div>
				</Container>

				{linkClientModal && (
					<LinkClientModal
						onClientLinked={this.onClientLinked}
						onClose={() => this.setState({linkClientModal: false})}
					/>
				)}
			</Fragment>
		);
	}
}

export default User