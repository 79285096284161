import
	React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import IconButton from "./IconButton";

class VerticalController extends Component{
	constructor(props){
		super(props);

	}

	render() {
		const {
			size,
			color,
            rounded,
            label,
            "top-icon" : topIcon,
            "icon" : icon,
			"bottom-icon" : bottomIcon,
			"top-action": topAction,
			"bottom-action": bottomAction
 		} = this.props;


		return (
			<Fragment>
				<div
                    className="vertical-controller"
                >
                    <div className="top-button">
                        <IconButton icon={topIcon} action={topAction}/>
                    </div>

                    <div>
                        <i className={`fa fa-${icon}`}></i>
                    </div>

                    <div className="bottom-button">
                        <IconButton icon={bottomIcon} action={bottomAction}/>
                    </div>
                </div>

			</Fragment>
		);
	}
}

VerticalController.propTypes = {
	label: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	size: PropTypes.string,
	color: PropTypes.bool,
	rounded: PropTypes.bool
};

VerticalController.defaultProps = {
	size: "medium",
	color: "standard"
};

export default VerticalController;
