import React from "react";
import PropTypes from "prop-types";
import {Link as ReactLink} from "react-router-dom";

const Link = ({children, href, className, onClick, ...props}) => {
	className = className ? className : "has-text-weight-light has-text-white";

	return href ? (
		<ReactLink
			to={href}
			className={className}
			{...props}
		>
			{children}
		</ReactLink>
	) : (
		<a
			role="button"
			className={className}
			onClick={onClick}
		>
			{children}
		</a>
	)

};

Link.propTypes = {
	href : PropTypes.string,
	onClick : PropTypes.func,
	children : PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.array,
		PropTypes.string
	])
};

Link.styles = {


}



export default Link