import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";

import GenericHeader from "./elements/headers/GenericHeader";
import { api, paths } from "../services/api";
import NewEnvironmentModal from "./modals/NewEnvironmentModal";

function Environment(props){
	const { environment, onRemoved, onEdited, hideRemoveButton } = props;

	const [editModal, setEditModal] = useState(false);

	const onEditClicked = () => setEditModal(true);
	const onRemoveClicked = async () => {
		await api.delete(paths.environment, {environment: environment.id});
		onRemoved();
	};



	const buttons = [
		// { label: "Scheduling" },
		// { label: "Add User" },
		{
			label: "Edit",
			onClick: onEditClicked
		},
		{
			label: "Remove",
			hide: hideRemoveButton,
			onClick: onRemoveClicked
		}
	];

	return (
		<Fragment>
			<GenericHeader
				title={environment.label}
				buttons={buttons}
			/>

			{editModal && (
				<NewEnvironmentModal
					environment={environment}
					onClose={setEditModal}
					onEdited={onEdited}
				/>
			)}


		</Fragment>
	);
}


Environment.propTypes = {
	environment: PropTypes.object.isRequired,
	onRemoved: PropTypes.func,
	hideRemoveButton: PropTypes.bool
};

Environment.defaultProps = {
	onRemoved: () => {},
	hideRemoveButton: true
};

export default Environment;
