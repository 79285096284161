import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {deviceContext} from "../../viotContext";
import { parseElements } from "../../services/TemplateEngine";
import Button from "./Button";

class Checkbox extends Component{
	constructor(props){
		super(props);

		this.state = {
			currentValue: false
		};

		this.checkboxChanged = this.checkboxChanged.bind(this);
	}

	async checkboxChanged(device, checkbox){
		const {action, data, state} = this.props;

		const value = checkbox.target.checked;
		console.log("checkbox value is:", value, state)

		await device.sendAction({command : action, value: {value, data}});
		// const {action} = this.props;
		// await device.sendAction({command : action, value: {value: button.value, data: button.data}});
		// this.setState({activeButton : button});
	}

	render(){
		const {options, centered, state} = this.props;

		return (
			<Fragment>
				<div className="columns">
					<deviceContext.Consumer>
						{device => {
							// console.log("Device state is: ", device.state.state)
							return (
								<div className={`column controls ${centered ? "has-text-centered" : ""}`}>
									<label className="checkbox">
										<input
											type="checkbox"
											className="checkbox"
											onChange={(event) => this.checkboxChanged(device, event)}
											checked={device.state[state]}
										/>
										<span className="checkmark"></span>

									</label>
									{/*{buttons.map(button => {*/}
									{/*	return (*/}
									{/*		<select*/}
									{/*			label={button.label}*/}
									{/*			active={!button.toggleable && device.state[state] === button.value}*/}
									{/*			size={size}*/}
									{/*			color={button.color || color}button*/}
									{/*			rounded={button.rounded || rounded}*/}

									{/*			toggleable={button.toggleable}*/}
									{/*			action={button.action}*/}
									{/*			toggled={device.state[button.state]}*/}
									{/*			toggleOnColor={button.toggleOnColor ? button.toggleOnColor : "success"}*/}
									{/*			toggleOffColor={button.toggleOffColor ? button.toggleOffColor : "failure"}*/}

									{/*			onClick={async () => {*/}
									{/*				console.log("our toggle is:", typeof button.toggleable)*/}
									{/*				if(button.toggleable){*/}
									{/*					console.log("is toggle")*/}
									{/*					await this.toggleButtonClicked(device, button);*/}
									{/*					return*/}
									{/*				}*/}
									{/*				console.log("calling ubtton clicked");*/}

									{/*				await this.buttonClicked(device, button);*/}
									{/*			}}*/}
									{/*		/>*/}
									{/*	);*/}
									{/*})}*/}
								</div>
							);
						}}
					</deviceContext.Consumer>
				</div>
			</Fragment>
		)
	}
}

Checkbox.propTypes = {
	buttons : PropTypes.array.isRequired,
	action : PropTypes.string.isRequired,
	size : PropTypes.string.isRequired,
	color : PropTypes.string.isRequired
};

export default Checkbox;