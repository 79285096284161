import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import env from "../../config"
import {api, paths} from "../../services/api";
import Tile from "../control/Tile"
import Modal from "../Modal";

class SpaceModal extends Component {
    constructor(){
        super();

        this.state = {
            isLoading : false,
            spaceImages : []
        };
        this.labelInput = createRef();

        this.newSpace = this.newSpace.bind(this);
        this.editSpace = this.editSpace.bind(this);
        this.formChanged = this.formChanged.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    async componentDidMount(){
        const { space } = this.props;
        this.labelInput.current.focus();

        const spaceImages = await api.get(paths.environmentSpaceImages);
        if(spaceImages && spaceImages.data) this.setState({spaceImages : spaceImages.data});

        if(space)
            this.setState({ selectedImage : space.image_id });
    }


    handleResponse(response){
        const {onSubmit, onClose} = this.props;
        if(response.status === "ok"){
            onSubmit(response.data);
            onClose();
        } else {
            swal.fire({
                type : "error",
                text : response.message
            });

            this.setState({
                isLoading : false
            });
        }  
    }

    async newSpace(){
        const {environment, onSubmit, onClose} = this.props;
        const {isLoading, label, selectedImage} = this.state;

        if(isLoading) return;
        this.setState({
            isLoading : true
        });

        const response = await api.post(paths.environmentSpaces, {
            environment : environment.id,
            label,
            image : selectedImage
        });

        this.handleResponse(response);

        
    }

    async editSpace(){
        const {space} = this.props;
        const {selectedImage, label, isLoading} = this.state;

        if(isLoading) return;

        this.setState({
            isLoading : true
        });

        const response = await api.post(paths.space, {
            space : space.id,
            label : label ? label : space.label,
            image : selectedImage
        });

        this.handleResponse(response);
    }

    formChanged(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    render(){
        const {environment, space, onClose, onSubmit} = this.props;
        const {isLoading, spaceImages, selectedImage} = this.state;


        return (
            <Modal
                isOpen={true}
                closeable={true}
                onClose={onClose}
                onSubmit={environment ? this.newSpace : this.editSpace}
                footerLeft={space && (
                    <button
                    type="submit"
                    onClick={async () => {
                        const result = await swal.fire({
                            title : "Confirm",
                            type : "warning",
                            showCancelButton: true,
                            text : `Are you sure you wish to remove ${space.label}?`
                        });

                        if(result.value){
                            const response = await api.delete(paths.space, {space : space.id});
                            if(response.status !== "ok") return swal.fire({title : "Error", text : response.message});
                            onSubmit();
                        }
                    }}
                    className="button is-primary"
                    >
                        Remove</button>
                )}
                footerRight={(
                    <button
                        type="submit"
                        onClick={environment ? this.newSpace : this.editSpace}
                        className={`button is-primary ${isLoading && "is-loading"}`}
                    >
                        {environment ? "Create" : "Save"}
                    </button>
                )}
                
                label={environment ? `New space for ${environment.label}` : `Edit ${space.label}`}
            >
                <div className="field">
                    <div className="column">
                        <div className="columns">
                            <div className="column is-4">
                                <label className="label has-help">Label</label>
                                <small>The name of your space</small>
                            </div>

                            <div className="column">
                                <div className="control">
                                    <input
                                        className="input"
                                        ref={this.labelInput}
                                        placeholder="Lounge"
                                        required pattern=".{2,}"
                                        name="label"
                                        type="text"
                                        defaultValue={space.label}
                                        onChange={this.formChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field">
                    <div className="column">
                        <div className="columns">
                            <div className="column is-4">
                                <label className="label has-help">Image</label>
                                <small>Identifying image</small>
                            </div>

                            <div className="column">
                                <div className="columns is-multiline">
                                    {spaceImages && spaceImages.map((image) => (
                                        <div className="column is-4-tablet" style={{padding: ".25rem"}} >
                                        <Tile
                                            backgroundImage={image.url}
                                            isSelected={image.id === selectedImage}
                                            onClick={() => {
                                                this.setState({
                                                    selectedImage : image.id
                                                })
                                            }}
                                        />
                                        </div>
                                    ))}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        )
    }
}

SpaceModal.propTypes = {
    environment : PropTypes.object,
    onClose : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    space : PropTypes.object
};

SpaceModal.defaultProps = {
    onClose : () => {},
    space : false
};

export default SpaceModal