import React, {Component, Fragment, StyleSheet} from "react";
import PropTypes from "prop-types";
import { parseElements } from "../../services/TemplateEngine";
import {deviceContext} from "../../viotContext";

class Tabs extends Component{
	constructor(props){
		super(props);

		this.state = {
			selectedTab : props.tabs[0]
		};

		this.selectTab = this.selectTab.bind(this);
	}

	async selectTab(tab, device){
		if(tab.toggleable){
			console.log("tab is ", tab)
			await device.sendAction({command : tab.action, value: {value: !device.state[tab.state], data: tab.data}});
			return
		}

		this.setState({selectedTab : tab});
	}

	render(){

		const { tabs, isSecondary, inTab } = this.props;
		const { selectedTab } = this.state;

		const tabStyles = (tab, device) => {
			if(tab.toggleable)
				return (device.state[tab.state]) ? styles.toggleTabLinkActive : styles.toggleTabLinkInactive;

			return tab === selectedTab ? styles.activeTabLink : {};
		}


		return (
			<Fragment>
				<div className={`tabs ${isSecondary ? "is-secondary" : ""} ${inTab ? "in-tab" : ""}`} style={styles.tabs}>
					<deviceContext.Consumer>
						{device => (
							<ul style={styles.tabList}>
								{tabs.map((tab) => (
									<li key={tab.label}>
										<a
											style={{...styles.tabLink, ...tabStyles(tab, device)}}
											onClick={() => this.selectTab(tab, device)}
										>
											{tab.label}
										</a>
									</li>

								))}
							</ul>
						)}
					</deviceContext.Consumer>
				</div>
				<div className={isSecondary ? "" : "content"}>
					{tabs.map((tab) => (
						tab === selectedTab && selectedTab.content ? (selectedTab.content.map(parseElements)) : (<div/>)
					))}
					{/*{selectedTab && selectedTab.content && selectedTab.content.map(parseElements)}*/}
				</div>
			</Fragment>
		)
	}
}

const styles = {
	tabs: {

	},
	tabList : {
		borderBottomColor: "rgba(255,255,255,.1)"
	},
	tabLink: {

	},
	activeTabLink: {
		borderBottomColor: "rgba(255,255,255,0.2)",
		backgroundColor: "rgba(255, 255, 255, 0.05)"
	},

	toggleTabLinkInactive: {
		borderBottomColor: "rgba(255,0,0,1)"
	},
	toggleTabLinkActive: {
		borderBottomColor: "rgba(0,255,0,1)"

	},
	column: {
		padding : 0
	}
};

Tabs.propTypes = {
	tabs : PropTypes.array.isRequired,
	isSecondary : PropTypes.bool
};

export default Tabs;