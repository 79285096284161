import React, { Component, Fragment } from "react";
import Collapsible from "react-collapsible";
import PropTypes from "prop-types";
import Link from "./Link";
import { formatSpaceUrl } from "../../services/util";
import ViewDeviceDetailsModal from "../modals/ViewDeviceDetailsModal";
import NewDeviceModal from "../modals/NewDeviceModal";
import {api, paths} from "../../services/api";
import TemplateEditor from "../modals/TemplateEditor";
import NewTemplateModal from "../modals/NewTemplateModal";
import swal from "sweetalert2";

class Device extends Component {
	constructor(props) {
		super(props);

		this.state = {
			template: props.template,
			templateEditorModal : false,
			editModal: false
		};

		this.toggleEditModal = this.toggleEditModal.bind(this);
		this.onTemplateEdited = this.onTemplateEdited.bind(this);
		this.toggleTemplateEditorModal = this.toggleTemplateEditorModal.bind(this);
	}

	toggleEditModal(){
		const {editModal} = this.state;
		this.setState({editModal: !editModal});
	}

	onTemplateEdited(label){
		const {template} = this.state;
		this.setState({
			template : {...template, label},
			editModal: false
		});

		swal.fire({type: "success", title: "Success!", text: "Template updated"})
	}

	toggleTemplateEditorModal(){
		const {templateEditorModal} = this.state;
		this.setState({
			templateEditorModal: !templateEditorModal
		})
	}

	async componentDidMount() {

	}

	render() {
		const {
			template,
			editModal,
			templateEditorModal
		} = this.state;

		return (
			<Fragment>
				<div className={`card template is-danger`}>
					<div className="card-content">
						<div className="media">
							<div className="media-content">
										<div className="level" style={{ marginTop: 0 }}>
											<div className="level-left">
												<div className="level-item">
													<p className="title is-5 template-title">{template.label}</p>
												</div>

											</div>
										</div>

								<div className="level">
										<div className="level-left">
											<div className="level-item">
												<Link onClick={this.toggleEditModal}>
													Edit
												</Link>
											</div>
										</div>


										<div className="level-right">
											<div className="level-item">
												<Link onClick={this.toggleTemplateEditorModal}>
													Launch editor
												</Link>
											</div>
										</div>
									</div>

							</div>
						</div>
					</div>
				</div>

				{templateEditorModal && (
					<TemplateEditor
						onClose={this.toggleTemplateEditorModal}
						template={template}
					/>
				)}
				{editModal && (
					<NewTemplateModal
						onEdited={this.onTemplateEdited}
						template={template}
						onClose={this.toggleEditModal}
					/>
				)}
			</Fragment>
		);
	}
}

Device.propTypes = {
	device: PropTypes.object.isRequired,
	onDetailsViewed: PropTypes.func.isRequired,
	onDeleted: PropTypes.func.isRequired
};

Device.defaultProps = {
	label: {}
};

export default Device;
