import React from "react";
import {Columns, Container} from "react-bulma-components";
import FlipMove from "react-flip-move";
import PropTypes from "prop-types";
import Tile from "./control/Tile";
import { formatSpaceUrl } from "../services/util";


const Spaces = (props) => {
	const {
		spaces,
		showNewSpace,
		newSpace,
		onStarred
	} = props;

	return (
		<Container>
			<Columns>
				<FlipMove
					typeName={null}
					staggerDelayBy={50}
					duration={750}
					appearAnimation="fade"
					enterAnimation="fade"
					leaveAnimation="fade"
				>
					{spaces.map((space) => (
						<div className="column is-3-fullhd is-4-desktop is-6-tablet is-12-mobile" key={space.id}>
							<Tile
								backgroundImage={space.image}
								text={space.label}
								href={formatSpaceUrl(space.id, space.label)}
								isStarred={space.starred}
								onStarred={() => onStarred(space)}
							/>
						</div>
					))}

					{showNewSpace && (
						<div className="column is-3-fullhd is-4-desktop is-6-tablet is-12-mobile">
							<Tile
								onClick={newSpace}
								text="+"
							/>
						</div>
					)}
				</FlipMove>
			</Columns>
		</Container>
	);
};

Spaces.propTypes = {
	spaces: PropTypes.array.isRequired,
	onStarred: PropTypes.func.isRequired,
	showNewSpace: PropTypes.bool.isRequired,
	newSpace: PropTypes.func.isRequired
};

export default Spaces;
