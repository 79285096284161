import io from "socket.io-client";
import env from "../config";
import {toast} from "react-toastify";


const socketConnection = io(env.WS_URL);

socketConnection.on("connect", () => {
});

socketConnection.on("device-connected", (device) => {
	toast(`${device.label} (${device.name}) has connected`, {type: "success", closeButton: false})
});

export default socketConnection;