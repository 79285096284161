import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import {api, paths} from "../../services/api";
import Tile from "../control/Tile"
import Modal from "../Modal";
import PageText from "../elements/PageText";
import Link from "../elements/Link";

class LinkDeviceModal extends Component {
	constructor(){
		super();

		this.state = {
            isLoading: false,
            selectedDevice : false,
            devices: []
        };
        
        this.linkDevice = this.linkDevice.bind(this);
        this.deviceChanged = this.deviceChanged.bind(this); 
	}

	async componentDidMount(){
        const {onClose} = this.props;

        const devices = await api.get(paths.devicesUnlinked);
        if(devices.status === "ok")
            this.setState({
                devices : devices.data,
                selectedDevice : devices.data[0] && devices.data[0].id
            });
        else {
            await swal.fire({
                type: "error",
                text: devices.message
            });

            onClose();
        }

    }
    
    deviceChanged(event){
        this.setState({selectedDevice : event.target.value});
    }

	async linkDevice(){
        const {isLoading, selectedDevice} = this.state;
        const {space, onDeviceLinked} = this.props;

        if(isLoading) return false;
        this.setState({ isLoading : true});
        
        const linkResult = await api.post(paths.deviceLink, {space, device: selectedDevice});
        if(linkResult.status === "ok"){
            onDeviceLinked(linkResult.data);
        } else {
            swal.fire({
                type : "error",
                text : linkResult.message
            });
        }

        this.setState({ isLoading : false});

    }
    

	formChanged(event){
		this.setState({
			[event.target.name] : event.target.value
		});
	}

	render(){
		const { onClose, spaceName } = this.props;
		const { isLoading, devices } = this.state;

		return (
			<Modal
				isOpen
				closeable
				onClose={onClose}
				onSubmit={this.linkDevice}
				footer={devices.length > 0 && (
					<a
						onClick={this.linkDevice}
						disabled={isLoading}
						className={`button is-primary ${isLoading && "is-loading"}`}
					>
						Link device
					</a>
				)}
				label={`Link device to ${spaceName}`}
			>
                {devices.length > 0 && (
                    <div className="field">
                        <div className="column">
                            <div className="columns">
                                <div className="column is-4">
                                    <label className="label has-help">Device name</label>
                                    <small>The device you wish to link</small>
                                </div>

                                <div className="column">
                                    <div className="control">
                                        <select className="select" name="device" onChange={this.deviceChanged}>
                                        
                                                {devices.map((device) => (
                                                    <option value={device.id} key={device.id}>{device.label} ({device.name})</option>
                                                ))}
                                        
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {devices.length <= 0 && (
					<PageText modal>You have no unlinked devices. <Link className="is-link" to="/devices">Add one</Link></PageText>
                )}

			
			</Modal>
		)
	}
}

LinkDeviceModal.propTypes = {
	onClose: PropTypes.func.isRequired,
    onDeviceLinked : PropTypes.func.isRequired,
    space : PropTypes.number.isRequired,
    spaceName : PropTypes.string.isRequired
};

LinkDeviceModal.defaultProps = {
	onClose : () => {}
};

export default LinkDeviceModal;
