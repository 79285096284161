import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";

class Button extends Component{
	render() {
		const {
			label,
			active,
			size,
			color,
			rounded,
			children,
			className,
			toggleable,
			toggled,
			onClick
		} = this.props;

		return (
			<Fragment>
				<button
					className={`
                        button
                        ${rounded ? "is-rounded" : ""}
						is-control
                        is-${size}
                        is-${color}
                        ${active ? "is-active" : ""}
                        ${toggleable ? toggled ? "is-success" : "is-danger" : ""}
						${className ? className : ""}
                    `}
					onClick={onClick}
				>
					{children ? children : `${label}${toggleable ? toggled ? " enabled" : " disabled" : ""}`}
				</button>
			</Fragment>
		);
	}
}

Button.propTypes = {
	label: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	size: PropTypes.string,
	onClick: PropTypes.func,
	color: PropTypes.bool,
	toggleable: PropTypes.bool,
	toggled: PropTypes.bool
};

Button.defaultProps = {
	size: "medium",
	color: "standard",
	onClick: () => {}
};

export default Button;
