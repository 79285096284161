import React, { Fragment } from "react";

import {
	Container, Columns
} from "react-bulma-components";

import swal from "sweetalert2";
import FlipMove from "react-flip-move";

import GenericPage from "./Generic";
import GenericHeader from "../elements/headers/GenericHeader";
import LinkDeviceModal from "../modals/LinkDeviceModal";
import SpaceModal from "../modals/SpaceModal";
import UnlinkDeviceModal from "../modals/UnlinkDeviceModal";
import Link from "../elements/Link";
import { api, paths } from "../../services/api";
import PageText from "../elements/PageText";
import DeviceTile from "../DeviceTile";

class Devices extends GenericPage {
	constructor(props) {
		super(props);

		this.state = {
			linkDevice: false,
			unlinkDevice: false,
			editSpace: false,
			isLoading: true,

			space: { hasLinkableDevices: false },
			devices: []
		};

		this.onDeviceStarred = this.onDeviceStarred.bind(this);
	}

	async componentDidMount() {
		const { spaceId } = this.props.match.params;

		const space = await api.get(paths.space, { space: spaceId });
		if (space.status === "ok") {
			this.setState({
				devices: space.data.devices,
				space: space.data.space
			});

			for (const device of space.data.devices) {
				api.get(paths.deviceStatus, { device: device.id}).then((data) => {
					if (data.status === "ok") {
						device.status = data.data;

						this.setState({
							devices : space.data.devices
						});
					}
				});
			}
		} else {
			await swal.fire({
				type: "error",
				text: space.message
			});

			this.props.history.push("/");
		}

		this.setState({ isLoading: false });
	}

	async onDeviceStarred(device){
		const {devices, space} = this.state;
		device.starred = !device.starred;

		this.setState({
			devices: devices.sort((a, b) => {
				const starred = b.starred - a.starred;
				return starred || (a.id > b.id ? 1 : -1);
			})
		});

		await api.post(paths.spaceDeviceStar, {
			space: space.id,
			device: device.id,
			starred: device.starred ? 1 : 0
		});
	}

	render() {
		const {
        	space,
			devices,
			linkDevice,
			editSpace,
			unlinkDevice,
			isLoading
		} = this.state;

		const {
        	history,
			match
		} = this.props;

		const { space : spaceName, spaceId } = match.params;

		return (
			<Fragment>
				<GenericHeader
					title={space.label}
					buttons={[
						{
							label: "Link device",
							hide: space.hasLinkableDevices <= 0,
							onClick: () => this.setState({ linkDevice: true })

						},
						{
							label: "Unlink device",
							hide: devices.length <= 0,
							onClick: () => this.setState({ unlinkDevice: true })
						},
						{
							label: "Edit",
							onClick: () => this.setState({ editSpace: true })
						}
					]}
				/>

				{!isLoading && devices.length <= 0 && space && (
					<PageText>
						{space.label} has no linked devices. <Link
							href="/devices"
							className="is-link"
						>Link a device</Link> to get started
					</PageText>
				)}

				{devices.length > 0 && (
					<Container>
						<Columns>
							<FlipMove
								typeName={null}
								staggerDelayBy={50}
								duration={750}
								appearAnimation="fade"
								enterAnimation="fade"
								leaveAnimation="fade"
							>
								{devices.map((device) => (
									<div className="column is-3-fullhd is-4-desktop is-6-tablet is-12-mobile" key={device.id}>
										<DeviceTile
											device={device}
											spaceId={spaceId}
											spaceName={spaceName}
											onStarred={() => this.onDeviceStarred(device)}
										/>

									</div>
								))}
							</FlipMove>
						</Columns>
					</Container>
				)}

				{linkDevice && (
					<LinkDeviceModal
						space={space.id}
						spaceName={space.label}
						onDeviceLinked={async (device) => {
							devices.push(device);
							space.hasLinkableDevices--;

							await api.get(paths.deviceStatus, {device: device.id}).then((data) => {
								if (data.status === "ok") {
									device.status = data.data;
								}
							});

							this.setState({
								linkDevice: false,
								devices,
								space
							});
						}}
						onClose={() => {
							this.setState({
								linkDevice: false
							});
						}}
					/>
				)}

				{unlinkDevice && (
					<UnlinkDeviceModal
						spaceName={space.label}
						devices={devices}
						onDeviceUnlinked={(device) => {
							const newDevices = devices.filter((device_) => device_.id !== device);
							space.hasLinkableDevices++;

							this.setState({
								unlinkDevice: false,
								devices: newDevices,
								space
							});
						}}
						onClose={() => {
							this.setState({
								unlinkDevice: false
							});
						}}
					/>
				)}

				{editSpace && (
					<SpaceModal
						space={space}
						onSubmit={(editedSpace) => {
							if (!editedSpace) return history.push("/");
							this.setState({ space: { ...space, ...editedSpace } });
						}}
						onClose={() => {
							this.setState({ editSpace: false });
						}}
					/>
				)}
			</Fragment>
		);
	}
}

export default Devices;
