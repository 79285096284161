import React, { Component, Fragment } from "react";
import Collapsible from "react-collapsible";
import PropTypes from "prop-types";
import Link from "./Link";
import { formatSpaceUrl } from "../../services/util";
import ViewDeviceDetailsModal from "../modals/ViewDeviceDetailsModal";
import NewDeviceModal from "../modals/NewDeviceModal";
import {api, paths} from "../../services/api";

class Device extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showKey: false,
			isLoadingImage: true,
			device: props.device
		};

		this.refreshDeviceStatus = this.refreshDeviceStatus.bind(this);
	}

	async componentDidMount() {
		const { device } = this.props;

		if (device.image) {
			const image = new Image();
			image.src = device.image;
			image.onload = () => this.setState({ isLoadingImage: false });
		}

		await this.refreshDeviceStatus();
	}

	async refreshDeviceStatus(){
		const {device} = this.props;
		await api.get(paths.deviceStatus, { device: device.id}).then((data) => {
			if (data.status === "ok") {
				this.setState({status : data.data});
			}
		});

		this.refreshTimeout = setTimeout(this.refreshDeviceStatus, 60000);
	}

	componentWillUnmount() {
		if(this.refreshTimeout)
			clearTimeout(this.refreshTimeout)
	}

	render() {
		const { onDeleted } = this.props;
		const {
			status,
			isLoadingImage,
			viewDetailsModal,
			editModal,
			device
		} = this.state;
		const {
			name,
			label,
			image
		} = device;

		const backgroundImage = !isLoadingImage ? `url(${image})` : "none";
		const deviceStatus = status === "online" ? "Online" : `Last seen: ${device.lastSeen ? device.lastSeen : "Never"}`;

		return (
			<Fragment>
				<div className={`card device is-${status}`} style={{ backgroundImage }}>
					<div className="card-content">
						<div className="media">
							<div className="media-content">
								<Collapsible
									triggerDisabled={!device.space}
									open={!device.space}
									trigger={(
										<div className="level" style={{ marginTop: 0 }}>
											<div className="level-left">
												<div className="level-item">
													<p className="title is-5">{label}</p>
												</div>

											</div>

											<div className="level-right">
												<div className="level-item">
													<p style={{ fontSize: "0.85rem", marginTop: 0, color: "rgba(255,255,255,0.5)" }}>
#
														{name}
													</p>
												</div>

											</div>

										</div>
									)}
								>

									<p className="is-6">
										{device.space ? (
											<span>
												Linked to
												{" "}
												<Link className="is-link" href={formatSpaceUrl(device.space, device.spaceLabel)}>{device.spaceLabel}</Link>
											</span>
										) : "Unlinked"}
									</p>
									<p className="is-6">{deviceStatus}</p>
									<div className="level">
										<div className="level-left">
											<div className="level-item">
												<Link onClick={() => {
													this.setState({
														editModal: true
													});
												}}
												>
Edit
												</Link>
											</div>
										</div>


										<div className="level-right">

											<div className="level-item">
												<Link onClick={() => {
													this.setState({
														viewDetailsModal: true
													});
												}}
												>
View details
												</Link>
											</div>
										</div>
									</div>

								</Collapsible>
							</div>
						</div>
					</div>
				</div>

				{viewDetailsModal && (<ViewDeviceDetailsModal device={device} onClose={() => { this.setState({ viewDetailsModal: false }); }} />)}
				{editModal && (
					<NewDeviceModal
						device={device}
						onSubmit={(editedDevice) => {
							this.setState({ device: { ...device, ...editedDevice } });
						}}
						onDeleted={onDeleted}
						onClose={() => {
							this.setState({ editModal: false });
						}}
					/>
				)}
			</Fragment>
		);
	}
}

Device.propTypes = {
	device: PropTypes.object.isRequired,
	onDetailsViewed: PropTypes.func.isRequired,
	onDeleted: PropTypes.func.isRequired
};

Device.defaultProps = {
	label: {}
};

export default Device;
