import React from 'react';
import {Link, withRouter} from "react-router-dom";
import { Navbar } from "react-bulma-components";
import {api, paths} from "../../services/api";

const Item = (props) => {
	const path = ('/'+window.location.pathname.substring(1).replace(/[^\w+].*$/,''));
	return (
		<Link className={`navbar-item ${path === props.to ? "is-active" : ""}`} to={props.to}>{props.children}</Link>
	)
}

export class Nav extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	render() {
		const { isOpen } = this.state;
		const { user } = this.props;
		const { isAuthenticated } = user;

		return (
			<Navbar

				active={isOpen}
			>
				<Navbar.Brand>
					<Navbar.Item renderAs="a" href="/">
						viot
					</Navbar.Item>
					<Navbar.Burger
						onClick={() => this.setState({
							isOpen: !isOpen
						})}
					/>
				</Navbar.Brand>
				<Navbar.Menu>
					<Navbar.Container>
						<Item to="/">Home</Item>
						{isAuthenticated && (<Item to="/templates">Templates</Item>)}
						{isAuthenticated && (<Item to="/devices">Devices</Item>)}
						{/* {isAuthenticated && (<Item to="/docs">Docs</Item>)} */}
					</Navbar.Container>
					<Navbar.Container position="end">
						{isAuthenticated ?
							(<Link to="/user" className="navbar-item">{user.details.username}</Link>) :
							(<a className="navbar-item" href={api.getApiUrl(paths.githubAuth)}>Login with GitHub</a>)
						}
					</Navbar.Container>
				</Navbar.Menu>
			</Navbar>
		);
	}
}

export default withRouter(Nav)