import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import {api, paths} from "../../services/api";
import Tile from "../control/Tile"
import Modal from "../Modal";

class LinkClientModal extends Component {
    constructor(){
        super();

        this.state = {
            isLoading : false
        }

        this.labelInput = createRef();
        
        this.linkClient = this.linkClient.bind(this);
        this.formChanged = this.formChanged.bind(this);

    }

    componentDidMount(){
        this.labelInput.current.focus();
    }

    async linkClient(){
        const {onClientLinked, onClose} = this.props;
        const {code, isLoading} = this.state;

        if(isLoading) return;
        this.setState({isLoading : true});

        const response = await api.post(paths.linkClient, {
            code
        });

        if(response.status === "ok"){
            onClientLinked();
            onClose();
        } else {
            swal.fire({
                type : "error",
                text : response.message
            });

            this.setState({isLoading : false});
        }
    }

    formChanged(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    render(){
        const {onClose} = this.props;
        const {isLoading} = this.state;

        return (
            <Modal
                isOpen={true}
                closeable={true}
                onClose={onClose}
                onSubmit={this.linkClient}
                footer={(
                    <button type="submit" disabled={isLoading} class={`button is-primary ${isLoading && "is-loading"}`}>Link</button>
                )}
                label='Link a client to your account'
            >
                <div class="field">
                    <div class="column">
                        <div class="columns">
                            <div class="column is-4">
                                <label class="label has-help">Code</label>
                                <small>Enter the code displayed on your client</small>
                            </div>

                            <div class="column">
                                <div class="control">
                                    <input className="input" ref={this.labelInput} placeholder="" required name="code" type="text" onChange={this.formChanged}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        )
    }
}

LinkClientModal.propTypes = {
    onClose : PropTypes.func.isRequired,
    onClientLinked : PropTypes.func.isRequired,
}

LinkClientModal.defaultProps = {
    onClose : () => {},
    onDeleted : () => {},
}

export default LinkClientModal