import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {deviceContext} from "../../viotContext";
import { parseElements } from "../../services/TemplateEngine";
import Button from "./Button";

class ButtonBar extends Component{
    constructor(props){
        super(props);

        const { buttons=[]} = props;

        this.state = {
            activeButton : buttons[0]
        };

        this.buttonClicked = this.buttonClicked.bind(this);
        this.toggleButtonClicked = this.toggleButtonClicked.bind(this);
    }

    async buttonClicked(device, button){
        const {action} = this.props;
        await device.sendAction({command : action, value: {value: button.value, data: button.data}});
        this.setState({activeButton : button});
    }

    async toggleButtonClicked(device, button){

        const {action} = this.props;
        await device.sendAction({command : button.action, value: !device.state[button.state]});
    }

    render(){
        const {buttons = [], rounded, rightAlign, centered, size, fromState, color, state} = this.props;
        const {activeButton} = this.state;

        return (
            <Fragment>
                <div className="columns">
                    <deviceContext.Consumer>
                        {device => {
                            if(!device) device = {state : {}};
                            const useButtons = fromState && device.state[fromState] ? device.state[fromState] : buttons;

                            return (
                              <div
                                className={`column button-bar ${rightAlign ? "has-text-right": ""} ${
                                  centered ? "has-text-centered" : ""
                                }`}
                              >
                                {useButtons.map((button) => {
                                  return (
                                    <Button
                                      label={button.label}
                                      active={
                                        !button.toggleable &&
                                        device.state[state] === button.value
                                      }
                                      size={size}
                                      color={button.color || color}
                                      rounded={button.rounded || rounded}
                                      key={button.value}
                                      toggleable={button.toggleable}
                                      action={button.action}
                                      toggled={device.state[button.state]}
                                      toggleOnColor={
                                        button.toggleOnColor
                                          ? button.toggleOnColor
                                          : "success"
                                      }
                                      toggleOffColor={
                                        button.toggleOffColor
                                          ? button.toggleOffColor
                                          : "failure"
                                      }
                                      onClick={async () => {
                                        if (button.toggleable) {
                                          await this.toggleButtonClicked(
                                            device,
                                            button
                                          );
                                          return;
                                        }

                                        await this.buttonClicked(
                                          device,
                                          button
                                        );
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            );
                        }}
                    </deviceContext.Consumer>
                </div>
            </Fragment>
        )
    }
}

ButtonBar.propTypes = {
    buttons : PropTypes.array.isRequired,
    action : PropTypes.string.isRequired,
    size : PropTypes.string.isRequired,
    color : PropTypes.string.isRequired
};

export default ButtonBar;