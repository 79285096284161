import React from "react";
import PropTypes from "prop-types";
import {Animated} from "react-animated-css";

const PageText = (props) => {
	const { children, modal} = props;

	return (
		<Animated animationIn="fadeIn">
			<h1 className={`page-text has-text-centered ${modal ? "is-modal-text" : ""}`}>
				{children}
			</h1>
		</Animated>
	);
};

PageText.propTypes = {
	children: PropTypes.element,
	modal: PropTypes.bool
};

export default PageText;
