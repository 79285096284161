import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import {api, paths} from "../../services/api";
import Tile from "../control/Tile"
import Modal from "../Modal";

class NewDeviceModal extends Component {
	constructor(props){
		super(props);

		const {device} = props;

		this.state = {
			isLoading: false,
			templates: false,
			template: device.templateId,
			deviceImages : [],
			selectedImage : device.image_id
		};

		this.labelInput = createRef();


		this.newDevice = this.newDevice.bind(this);
		this.editDevice = this.editDevice.bind(this);
		this.formChanged = this.formChanged.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
	}

	async componentDidMount(){
		this.labelInput.current.focus();

		const deviceImages = await api.get(paths.deviceImages);
        if(deviceImages.status === "ok") this.setState({deviceImages : deviceImages.data});

        const templates = await api.get(paths.templates);
        if(templates.status === "ok") this.setState({templates: templates.data});
	}

	handleResponse(response){
		const {onSubmit, onClose} = this.props;

		if(response.status === "ok"){
			onSubmit(response.data);
			onClose();
		} else {
			swal.fire({
				type: "error",
				text: response.message
			});

			this.setState({
				isLoading : false
			});
		}
	}

	async editDevice(){
		const { onSubmit, onClose, device } = this.props;
		const { label, selectedImage, template, isLoading } = this.state;

		if(isLoading) return;
		this.setState({
			isLoading : true
		});

		const response = await api.post(paths.device, {template: template ? template : -1, device : device.id, label : label ? label : device.label, image : selectedImage});
		swal.fire({title:"Success", text: "Device added!", type: "success"});

		this.handleResponse(response);
	}

	async newDevice(){
		const { name, label, selectedImage, isLoading, template } = this.state;

		if(isLoading) return;
		this.setState({
			isLoading : true
		});

		const response = await api.post(paths.newDevice, {
			label,
			template: template ? template : -1,
			name,
			image : selectedImage
		});

		swal.fire({title:"Success", text: "Device added!", type: "success"});

		this.handleResponse(response);

		
	}

	formChanged(event){
		this.setState({
			[event.target.name] : event.target.value
		});
	}

	render(){
		const { onClose, device, onDeleted } = this.props;
		const { isLoading, deviceImages, templates, selectedImage } = this.state;

		return (
			<Modal
				isOpen
				closeable
				onClose={onClose}
				onSubmit={device.name ? this.editDevice : this.newDevice}
				footerLeft = {device.name && (
					<a onClick={async () => {
						const result = await swal.fire({
							title : "Confirm",
							type : "warning",
							showCancelButton: true,
							text : `Are you sure you wish to remove ${device.label}?`
						});

						if(result.value){
							const response = await api.delete(paths.device, {device : device.id});
							if(response.status !== "ok") return swal.fire({title : "Error", text : response.message});
							onClose();
							onDeleted();
						}
					}}
					className="button is-primary">Remove</a>
				)}
				footerRight={(
						
						<a
						onClick={device.name ? this.editDevice : this.newDevice}
						className={`button is-primary ${isLoading && "is-loading"}`}
					>
						{device.name ? "Save" : "Create"}
					</a>
					
				)}
				label={`${device ? "Edit" : "New"} Device`}
			>

<div className="field">
					<div className="column">
						<div className="columns">
							<div className="column is-4">
								<label className="label has-help">Label</label>
								<small>The label of your device which is displayed in the UI</small>
							</div>

							<div className="column">
								<div className="control">
									<input
										defaultValue={device.label}
										className="input"
										placeholder="Lights"
										required
										pattern=".{2,}"
										name="label"
										ref={this.labelInput}
										type="text"
										onChange={this.formChanged}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div className="field">
					<div className="column">
						<div className="columns">
							<div className="column is-4">
								<label className="label has-help">Name</label>
								<small>The unique name of your device - all lowercase, a-z and - only</small>
							</div>

							<div className="column">
								<div className="control">
									<input
										className="input"
										defaultValue={device.name}
										disabled={!!device.name}
										placeholder="lights-bedroom-home"
										required
										pattern=".{2,}"
										name="name"
										type="text"
										onChange={this.formChanged}
									/>
								</div>
							</div>
						</div>link
					</div>
				</div>

				<div className="field">
					<div className="column">
						<div className="columns">
							<div className="column is-4">
								<label className="label has-help">Template (optional)</label>
								<small>The non-dynamic template for your device</small>
							</div>

							<div className="column">
								<div className="columns is-multiline">
									<div className="column">
										<div className="control">
											{templates && (
												<select className="select" name="template" defaultValue={device.templateId} onChange={this.formChanged}>
													<option value={"-1"}>None</option>
													{templates.map((template) => (
														<option value={template.id} key={template.id}>{template.label}</option>
													))}
												</select>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div className="field">
					<div className="column">
						<div className="columns">
							<div className="column is-4">
								<label className="label has-help">Image</label>
								<small>Identifying image for your device</small>
							</div>

							<div className="column">
								<div className="columns is-multiline">
											{deviceImages.map((image) => (
												<div className="column is-4-tablet" style={{padding: ".25rem"}}>
													<Tile
														backgroundImage={image.url}
														isSelected={image.id === selectedImage}
														onClick={() => {
															this.setState({
																selectedImage: image.id
															})
														}}
													/>
												</div>
											))}
										</div>

							</div>
						</div>
					</div>
				</div>

			
			</Modal>
		)
	}
}

NewDeviceModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDeleted : PropTypes.func,
	device : PropTypes.object
};

NewDeviceModal.defaultProps = {
	onClose : () => {},
	device : {},
	onDeleted : () => {}
};

export default NewDeviceModal;
