import React, { Fragment } from "react";
import { Container } from "react-bulma-components";
import FlipMove from "react-flip-move";
import GenericPage from "./Generic";
import GenericHeader from "../elements/headers/GenericHeader";
import Device from "../elements/Device";
import { api, paths } from "../../services/api";

import Link from "../elements/Link";
import PageText from "../elements/PageText";
import Template from "../elements/Template";
import NewTemplateModal from "../modals/NewTemplateModal";
class Templates extends GenericPage {
	constructor(props) {
		super(props);

		this.state = {
			templates : [],
		};

		this.onTemplateAdded = this.onTemplateAdded.bind(this);
	}

	async componentDidMount() {
		const templates = await api.get(paths.templates);
		if(templates.status === "ok")
			this.setState({templates: templates.data});
	}

	onTemplateAdded(template) {
		const {templates} = this.state;

		const newTemplates = templates.concat(template);
		this.setState({templates: newTemplates});
	}

	render() {
		const {
			templates,
			newTemplate,
			isLoading
		} = this.state;
		return (
			<Fragment>
				<GenericHeader
					title="Templates"
					buttons={[{
						label: "Add template",
						onClick: () => {
							this.setState({ newTemplate: true });
						}
					}]}
				/>

				<Container className="is-centered">
					<div className="columns is-multiline">

						<FlipMove
							typeName={null}
							appearAnimation="fade"
							enterAnimation="fade"
							leaveAnimation="fade"
						>
							{templates.map((template) => (
								<div
									key={template.id}
									className="column is-4-fullhd is-4-desktop is-6-tablet is-12-mobile"
								>
									<Template template={template}/>
									{/*<Device*/}
									{/*	// device={device}*/}
									{/*	// onDeleted={() => {*/}
									{/*	// 	const newDevices = devices.filter((oldDevice) => oldDevice.id !== device.id);*/}
									{/*	// 	this.setState({ devices: newDevices });*/}
									{/*	// }}*/}
									{/*/>*/}
								</div>
							))}



						</FlipMove>

					</div>
				</Container>

				{!isLoading && templates.length <= 0 && (
					<PageText>
						You have no templates.

						<Link
							className="is-link"
							onClick ={() => {
								this.setState({ newTemplate: true });
							}}
						>
							{" "}
							Add one
						</Link>
					</PageText>
				)}

				{newTemplate && (
					<NewTemplateModal

						onTemplateAdded={this.onTemplateAdded}


						onClose={() => {
							this.setState({ newTemplate: false });
						}}
					/>
				)}
			</Fragment>
		);
	}
}

export default Templates;
