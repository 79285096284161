import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {deviceContext} from "../../viotContext";

class Slider extends Component{
    constructor(props){
        super(props);

        // TODO: Fix bug where slider is not updated for other clients due to if changing defaultValue it doesn't update the value and if you set value then you can't move the slider

        this.state = {
            displayValue : 0,
            isFocused : false,
            previousValue : 0
        };

        this.onChange = this.onChange.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    onChange(e){
        this.setState({displayValue: e.target.value});
    }

    async onChanged(device, e){
        const {action, data} = this.props;
        await device.sendAction({command : action, value: {value: e.target.value, data: data}});
    }

    render(){
        const {label, active, state, step, min, max} = this.props;
        const {displayValue, isFocused, previousValue} = this.state;



        return (
            <Fragment>
                <deviceContext.Consumer>
                    {device => {
                        if(device.state[state] !== previousValue){
                            this.setState({previousValue: device.state[state], displayValue: undefined})
                        }
                        return (
                            <div className="control">
                                <input
                                    className="slider is-fullwidth is-circle has-output"
                                    step={step}
                                    min={min}
                                    value={device.state[state] !== previousValue ? device.state[state] : undefined}
                                    max={max}
                                    // onMouseEnter={() => this.setState({isFocused: true})}
                                    // onMouseLeave={() => this.setState({isFocused: false})}
                                    // value={!isFocused ? device.state[state] : undefined}
                                    defaultValue={device.state[state]}
                                    type="range"
                                    onChange={this.onChange}
                                    onMouseUp={(e) => this.onChanged(device, e)}
                                    onTouchEnd={(e) => this.onChanged(device, e)}
                                />
                                <output>{displayValue ? displayValue : device.state[state]}</output>
                            </div>
                        );
                    }}
                </deviceContext.Consumer>
            </Fragment>
        )
    }
}

Slider.propTypes = {
    label : PropTypes.string.isRequired,
    active : PropTypes.bool.isRequired
};

export default Slider;