import React, {Fragment} from 'react';
import Link from "../elements/Link";
import {api, paths} from "../../services/api";
import FlipMove from "react-flip-move";
import GenericPage from "./Generic";
import GenericHeader from "../elements/headers/GenericHeader";
import SpaceModal from "../modals/SpaceModal"
import NewEnvironmentModal from '../modals/NewEnvironmentModal';
import Environment from "../Environment";
import Spaces from "../Spaces";
import PageText from "../elements/PageText";



class Home extends GenericPage{
	constructor(props){
		super(props);
		
		this.state = {
			environments : [],
			spaces : [],
			isLoading : true,
			starredDevices : [],
			isSpacesLoading : true
		};

		this.newEnvironment = this.newEnvironment.bind(this);
		this.onEnvironmentEdited = this.onEnvironmentEdited.bind(this);
		this.onEnvironmentRemoved = this.onEnvironmentRemoved.bind(this);
		this.onEnvironmentAdded = this.onEnvironmentAdded.bind(this);
		this.newSpace = this.newSpace.bind(this);
		this.onSpaceAdded = this.onSpaceAdded.bind(this);
		this.onSpaceStarred = this.onSpaceStarred.bind(this);
	}

	async componentDidMount(){
		const environments = await api.get(paths.environments);
		if(environments.status === "ok"){
			const spaces = [];

			this.setState({environments: environments.data, isLoading : false});

			for(let environment of environments.data){
				const spaceData = await api.get(paths.environmentSpaces, {environment : environment.id});
				if(spaceData && spaceData.status === "ok")
					for(let space of spaceData.data)
						spaces.push({...space, environment : environment.id});
			}

			this.setState({
				isSpacesLoading : false,
				spaces : spaces,
			});
		}

		const starredDevices = await api.get(paths.devicesStarred);
		if(starredDevices.status === "ok")
			this.setState({starredDevices : starredDevices.data});

	}

	newEnvironment(close){
		this.setState({newEnvironmentOpen : !close});
	}

	onEnvironmentEdited(editedEnvironment){
		const {environments } = this.state;
		const newEnvironments = environments.map(oldEnvironment => {
			if(oldEnvironment.id === editedEnvironment.id) {
				return {...oldEnvironment, ...editedEnvironment};
			}

			return oldEnvironment;
		});

		this.setState({environments : newEnvironments});
	}

	onEnvironmentAdded(newEnvironment) {
		const {environments} = this.state;


		const newEnvironments = environments.concat(newEnvironment);
		this.setState({environments: newEnvironments});
	}


	onEnvironmentRemoved(removedEnvironment){
		const {environments} = this.state;

		const newEnvironments = environments.filter(environment => environment.id !== removedEnvironment.id);
		this.setState({environments : newEnvironments});
	}


	newSpace(environment, close) {
		this.setState({newSpaceEnvironment : close ? false : environment})
	}

	onSpaceAdded(space) {
		const {spaces} = this.state;

		const newSpaces = spaces.concat(space);
		this.setState({spaces : newSpaces});
	}

	async onSpaceStarred(environment, starredSpace){
		const {spaces} = this.state;

		const newSpaces = spaces
			.map(space => {
				if(starredSpace.id === space.id)
					return {...space, starred : !space.starred};

				return space;
			})
			.sort((a, b) => {
				const starred = b.starred - a.starred;
				return starred ? starred : a.id > b.id ? 1 : -1
			});

		this.setState({spaces : newSpaces});

		await api.post(paths.environmentSpaceStar, {
			environment : environment.id,
			space: starredSpace.id,
			starred : starredSpace.starred ? 0 : 1
		});
	}

	render() {
		const {environments, spaces, isLoading, isSpacesLoading, newSpaceEnvironment, newEnvironmentOpen} = this.state;

		const filteredEnvironments = environments.filter((e, index) => isSpacesLoading ? index === 0 : true);

		const environmentButtons = [{
			label : "New Environment",
			onClick : () => this.newEnvironment()
		}];

		return (
			<Fragment>
				<GenericHeader title="Environments" buttons={environmentButtons}/>

				{!isLoading && 
					(environments.length > 0 ? (
						<FlipMove
							typeName={null}
							staggerDelayBy={50}
							duration={500}
							appearAnimation="fade"
							enterAnimation="fade"
							leaveAnimation="fade"
						>
							{filteredEnvironments.map((environment) => {
								const onRemoved = () => this.onEnvironmentRemoved(environment);
								const hideRemoveButton = isSpacesLoading
										|| !environment.admin
										|| spaces.filter(space => space.environment === environment.id).length > 0;

								const newSpace = () => this.newSpace(environment);
								const onStarred = (space) => this.onSpaceStarred(environment, space);
								const environmentSpaces = spaces.filter(space => space.environment === environment.id);
								const showNewSpace = environment.admin && !isSpacesLoading;


								return (
									<div key={environment.id}>
										<Environment
											onEdited={this.onEnvironmentEdited}
											onRemoved={onRemoved}
											environment={environment}
											hideRemoveButton={hideRemoveButton}
										/>

										<Spaces
											spaces={environmentSpaces}
											showNewSpace={showNewSpace}
											newSpace={newSpace}
											onStarred={onStarred}
										/>
									</div>
								)
							})}
						</FlipMove>
					) : (
						<PageText>
								You have no environments.
								<Link className="is-link" onClick={() => this.newEnvironment()}>Create one</Link> to get started
						</PageText>
					))
				}


				{newSpaceEnvironment && (
					<SpaceModal
						environment={newSpaceEnvironment}
						onSubmit={this.onSpaceAdded}
						onClose={() => this.newSpace(false, true)}
					/>
				)}

				{newEnvironmentOpen && (
					<NewEnvironmentModal
						onEnvironmentAdded={this.onEnvironmentAdded}
						onClose={() => this.newEnvironment(true)}
					/>
				)}

			</Fragment>
		);
	}
}

export default Home