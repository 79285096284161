import React, {Component} from "react";
import { Hero, Container, Heading, Level } from "react-bulma-components";
import PropTypes from "prop-types";

import Link from "../Link"
import { Animated } from "react-animated-css";

class GenericHeader extends Component {
    constructor(props){
        super(props)
    }

    render(){
        const { title, buttons, hide } = this.props;

        return (
            <Hero>
                <Hero.Body>
                    <Container>
                        <Animated animationIn="fadeIn" animationInDuration={300}>
                        <Level>
                     <Level.Side align="left">
                        <Level.Item>
                            <Heading
                                size={4}
                                weight="light"
                                className="has-text-white"
                            >
                                {title}
                            </Heading>
                        </Level.Item>
                    </Level.Side>

                    <Level.Side align="right">
                        {buttons.filter(button => !button.hide).map((button) => (
                            <Level.Item key={button.label}>
                                <Link href={button.href} onClick={button.onClick}>{button.label}</Link>
                            </Level.Item>
                        ))}

                    </Level.Side>
                        </Level>
                        </Animated>
                    </Container>
                </Hero.Body>
            </Hero>
        )
    }
}

GenericHeader.propTypes = {
    buttons : PropTypes.array,
    hide : PropTypes.bool,
    title : PropTypes.string.isRequired,
};

GenericHeader.defaultProps = {
    buttons : []
};

export default GenericHeader;