import env from "../config";


// TOOD: REMOVE:
/*
const posts = await doSOmething().catch(..)
*/


class Api {
	getApiUrl(api, params) {
		const pathParams = (api.match(/:([aA-zZ]*)/g) || []).map((match) => match.substring(1));

		for (const param in params) {
			if (pathParams.indexOf(param) !== -1) { api = api.replace(`:${param}`, params[param]); }
		}

		return new URL(api, env.SERVICE_URL);
	}

	async fetch(api, options) {
		const response = await fetch(api, {
			credentials: "include",
			...options
		});

		const json = await response.json();
		return { statusCode: response.status, ...json };
	}

	async get(api, params) {
		const url = this.getApiUrl(api, params);
		if (params) Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

		return await this.fetch(url, {
			method: "GET"
		});
	}

	async delete(api, params) {
		const url = this.getApiUrl(api, params);
		if (params) Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

		return await this.fetch(url, {
			method: "DELETE"
		});
	}

	async post(api, params) {
		const url = this.getApiUrl(api, params);
		return await this.fetch(url, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json"
			}
		});
	}
}


export const paths = {
	doSomething: "/docs/open-api",
	githubAuth: "/user/auth/github",
	user: "/user",
	linkClient: "/user/link-client",

	newEnvironment: "/environment",
	environments: "/environment/all",
	environment: "/environment/:environment",
	environmentSpaces: "/environment/:environment/space",
	environmentSpaceStar: "/environment/:environment/space/:space/star",
	environmentSpaceImages: "/space/images",

	space: "/space/:space",
	spaceDeviceStar: "/space/:space/:device/star",
	spaceDevice: "/space/:space/:device",

	templates: "/template",
	template: "/template/:template",
	templateJson: "/template/:template/template",



	newDevice: "/device",
	device: "/device/:device",
	devices: "/device/all",
	devicesUnlinked: "/device/unlinked",
	devicesStarred: "/device/starred",
	deviceImages: "/device/images",
	deviceLink: "/device/:device/link",
	deviceUnlink: "/device/:device/unlink",
	deviceStatus: "/device/:device/status",
	deviceTemplate: "/device/:device/template"
};

export const api = new Api();
