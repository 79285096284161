import React, {Fragment} from 'react';
import { Container, Columns, Hero, Heading } from "react-bulma-components";
import swal from "sweetalert2";
import GenericPage from "./Generic";
import GenericHeader from "../elements/headers/GenericHeader";
import {parseElements, parseModals} from "../../services/TemplateEngine";
import socket from "../../services/socket";
import {api, paths} from "../../services/api";
import {deviceContext} from "../../viotContext";

import Link from "../elements/Link";
import PageText from "../elements/PageText";
import {Animated} from "react-animated-css";


class Device extends GenericPage {
	constructor(props){
		super(props);


		this.state = {
			isLoading : true,
			activeModal: false
		};

		this.sendAction = this.sendAction.bind(this);
		this.isModalActive = this.isModalActive.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	async componentDidMount(){
		const {history} = this.props;
		const { spaceId, deviceId } = this.props.match.params;

		socket.on("state", (newState) => {
			this.setState({
				state: newState.state
			});
		});


		const device = await api.get(paths.spaceDevice, {
			space: spaceId,
			device: deviceId,
		});

		const error = async (message) => {
			await swal.fire({type: "error", text: message})
		};

		if(device.status === "ok"){
			const status = await api.get(paths.deviceStatus, {device : deviceId});
			if(status.status === "ok"){
				device.data.status = status.data;

				const template = await api.get(paths.deviceTemplate, {device : deviceId});
				if(template.status === "ok"){
					this.setState({
						device : device.data,
						template : template.data.template,
						state : template.data.state,
						isLoading : false
					});
				} else{
					await error(template.message);
					return history.push(`/space/${spaceId}`);
				}
			} else{
				return await error(status.message)
			}
		} else {
			await error(device.message);
			return this.props.history.push(`/space/${spaceId}`);
		}

		socket.emit("subscribe", {device: deviceId});

	}

	componentWillUnmount(){
		const {deviceId} = this.props.match.params;
		socket.emit("unsubscribe", {device: deviceId});
	}

	sendAction(action){
		const { deviceId } = this.props.match.params;
		console.log(action)
		if(action.command === "open-modal"){
			if(action.value && action.value.value){
				console.log("opened modal:", action.value.value)
				this.setState({activeModal: action.value.value})
			}

			return;
		}

		socket.emit("action", {action, device : deviceId});
	}

	isModalActive(modal){
		const {activeModal} = this.state;
		return activeModal === modal;
	}

	closeModal(){
		this.setState({activeModal: false});
	}



	render() {
		const {isLoading, device, template, activeModal, state} = this.state;
		const { spaceId } = this.props.match.params;
		const pageTitle = isLoading ? "" : device ? device.label : "Error";
		const goBack = () => this.props.history.push(`/space/${spaceId}`);

		const elements = template ? parseElements(template.control) : (<div/>);
		console.log(template && template.modals)
		const modals = template && template.modals ? parseModals(template.modals) : (<div/>)
		const size = template && template.size ? `is-${template.size}-desktop` : "is-9-fullhd is-11-desktop";
		const rounded = template && template.rounded ? "is-rounded" : "";

		const deviceCommunicator = {
			sendAction : this.sendAction,
			isModalActive: this.isModalActive,
			closeModal: this.closeModal,
			state : state
		};

		return (
      <Fragment>
        <GenericHeader
          title={pageTitle}
          buttons={[{ label: "Go Back", onClick: goBack }]}
        />
        <Container className="is-centered">
          {!isLoading ? (
            device && device.status === "online" ? (
              <deviceContext.Provider value={deviceCommunicator}>
                <>
                  <div className="columns is-centered">
                    <div className={`column ${size}`}>
                      <Animated
                        animationIn="fadeIn"
                        animationInDelay={100}
						animationInDuration={250}
						className={`box control-panel ${rounded}`}
                      >
                          {elements}
                      </Animated>
                    </div>
                  </div>
                  {modals}
                </>
              </deviceContext.Provider>
            ) : (
              <PageText>
                Device is offline.{" "}
                <Link className="is-link" onClick={goBack}>
                  Go Back
                </Link>
              </PageText>
            )
          ) : (
            <div />
          )}
        </Container>
      </Fragment>
    );
	}
}

export default Device