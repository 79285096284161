import React, { Fragment } from "react";
import { Container } from "react-bulma-components";
import FlipMove from "react-flip-move";
import GenericPage from "./Generic";
import GenericHeader from "../elements/headers/GenericHeader";
import Device from "../elements/Device";
import NewDeviceModal from "../modals/NewDeviceModal";
import { api, paths } from "../../services/api";

import Link from "../elements/Link";
import PageText from "../elements/PageText";

class Devices extends GenericPage {
	constructor(props) {
		super(props);

		this.state = {
			newDevice: false,
			deviceDetails: false,
			devices: [],
			environments: [],
			isLoading: true
		};
	}

	async componentDidMount() {
		const devices = await api.get(paths.devices);

		const setDevices = () => {
			this.setState({
				devices: devices.data.map((device) => (!device.space ? { ...device, environment: 0 } : device))
			});
		};

		if (devices.status === "ok"){
			setDevices();
		}

		(new Promise(async (res, rej) => {
			for (const device of devices.data) {
				await api.get(paths.deviceStatus, {device: device.id}).then((data) => {
					if (data.status === "ok") {
						device.status = data.data;
					}
				});
			}
			setDevices();
		}));

		const environments = await api.get(paths.environments);
		if (environments.status === "ok") {
			environments.data.push({
				label: "Unlinked",
				id: 0
			});

			this.setState({
				environments: environments.data.sort((a, b) => a.id - b.id)
			});
		}

		this.setState({ isLoading: false });
	}

	render() {
		const {
			newDevice, environments, devices, isLoading
		} = this.state;
		return (
			<>
				<GenericHeader
					title="Devices"
					buttons={[{
						label: "Add device",
						onClick: () => {
							this.setState({ newDevice: true });
						}
					}]}
				/>

				{!isLoading && environments.map((environment) => {
					const environmentDevices = devices.filter((device) => device.environment === environment.id);
					return environmentDevices.length > 0 ? (
						<Fragment>
							<GenericHeader title={environment.label} />
							<Container className="is-centered">
								<div className="columns is-multiline">

									<FlipMove
										typeName={null}
										appearAnimation="fade"
										enterAnimation="fade"
										leaveAnimation="fade"
									>
										{environmentDevices.map((device) => (
											<div
												key={device.id}
												className="column is-4-fullhd is-4-desktop is-6-tablet is-12-mobile"
											>
												<Device
													device={device}
													onDeleted={() => {
														const newDevices = devices.filter((oldDevice) => oldDevice.id !== device.id);
														this.setState({ devices: newDevices });
													}}
												/>
											</div>
										))}

									</FlipMove>

								</div>
							</Container>
						</Fragment>
					) : <div />;
				})}

				{!isLoading && devices.length <= 0 && (
					<PageText>
						You have no devices.

						<Link
							className="is-link"
							onClick={() => {
								this.setState({ newDevice: true });
							}}
						>
							{" "}
							Add one

						</Link>
						{" "}
						to get started
					</PageText>
				)}

				{newDevice && (
					<NewDeviceModal
						onSubmit={(device) => {
							devices.push({ ...device, environment: 0 });

							this.setState({
								newDevice: false,
								devices
							});
						}}
						onClose={() => {
							this.setState({ newDevice: false });
						}}
					/>
				)}
			</>
		);
	}
}

export default Devices;
