import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import { parseElements } from "../../services/TemplateEngine";



const Container = (props) => (
    <div className="template-container">
        {props.content.map((content) => parseElements(content))}
    </div>
);

const Content = (props) => (
    <div className={`
        content
    `}>
        {props.content.map((content) => (parseElements(content)))}
    </div>
)

const Columns = (props) => {
    return (
    <div className={`
        columns
        ${props.centered ? "is-centered has-text-centered is-vcentered" : ""}
    `}>
        {props.content.map((content) => (parseElements(content)))}
    </div>
)};


const Column = (props) => (
    <div className={`
        column
        ${props.size ? `is-${props.size}` : ""}
    `}>
        {props.content.map((content) => (parseElements(content)))}
    </div>
);


export {Container, Content, Columns, Column}