import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Collapsible from "react-collapsible";

class TemplateEditorItems extends Component{
	constructor(props){
		super(props);
	}

	getTree(element, nestCount) {
		return (
			<TemplateEditorItems
				label="waikodpakdpowak"
				tree={element.content || []}
				nestCount={nestCount + 1}
			/>
		)
	}


	render(){
		const {tree, nestCount = 0} = this.props;

		return (
			<div className="tres start">
				{tree.map((element) => (
					<div style={{position: "relative"}}>

						<Collapsible
							className="editor-item"
							open={false}
							triggerDisabled={!element.content}
							trigger={(
								<div className="level editor-item-level"
									 style={{textTransform: "capitalize", marginTop: 5}}>
									<div className="level-left">
										<div className="level-item">
											<p className="editor-item-label"
											   style={{paddingLeft: nestCount * 20}}>{element.type}</p>
										</div>

									</div>


								</div>
							)}
						>
							<div style={{position: "relative"}}>
								{element.content ? this.getTree(element, nestCount) : (<p></p>)}
							</div>
						</Collapsible>

					</div>

				))}
			</div>
		)
	}

}

TemplateEditorItems.propTypes = {
	label : PropTypes.string.isRequired,
	tree : PropTypes.array
};

TemplateEditorItems.defaultProps = {
}

export default TemplateEditorItems;