import React, { Component, Fragment } from 'react';
import Header from "./components/elements/Nav"
import Main from "./components/Main"
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import * as io from "socket.io-client";

import {userContext} from "./viotContext";
import {authorize} from "./services/user";

import './App.sass';

class App extends Component {
	constructor(props){
		super(props);

		this.state = {
			user : {}
		}
	}

	async componentDidMount(){
		const user = await authorize();

		this.setState({
			user : user.statusCode === 200 ? user.data : undefined
		});

		toast.configure();
	}

	render() {
		const user = {
			details: this.state.user || {},
			isAuthenticated: !!this.state.user
		};

		return (
			<Fragment>
				<ToastContainer />

				<userContext.Provider value={user}>
					<div>
						<userContext.Consumer>
							{(user) => (
								<div>
									<Header user={user}/>
									<Main/>
								</div>
							)}
						</userContext.Consumer>
					</div>
			</userContext.Provider>
			</Fragment>
		);
	}
}

export default App;