import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import swal from "sweetalert2";
import {api, paths} from "../../services/api";
import Tile from "../control/Tile"
import Modal from "../Modal";
import PageText from "../elements/PageText";

class ViewDeviceDetailsModal extends Component {
	constructor(){
		super();

		this.state = {
            isLoading: false,
            selectedDevice : false,
            device : {}
        };
	}

	async componentDidMount(){
        const {device : deviceProp, onClose} = this.props;

        const device = await api.get(paths.device, {device : deviceProp.id});
        if(device.status === "ok"){
            this.setState({device : device.data});
        }

        // const devices = await api.get(paths.devicesUnlinked);
        // if(devices.status === "ok")
        //     this.setState({
        //         devices : devices.data,
        //         selectedDevice : devices.data[0] && devices.data[0].id
        //     });
        // else {
        //     await swal.fire({
        //         type: "error",
        //         text: devices.message
        //     });

        //     onClose();
        // }

    }

	render(){
		const { onClose, device : deviceProp } = this.props;
		const { isLoading, device } = this.state;

		return (
			<Modal
				isOpen
				closeable
				onClose={onClose}
				footer={(
					<a
                        onClick={onClose}
                        className="button is-primary"
					>
						Close
					</a>
				)}
                label={`${deviceProp.label} (${deviceProp.name})`}
                

			>
 
					<div className="field">
                        <div className="column">
                            <div className="columns">
                                <div className="column is-4">
                                    <label className="label has-help">Device name</label>
                                    <small>The name of your device</small>
                                </div>

                                <div className="column">
								<div className="control">
									<input
										className="input"
										placeholder=""
										disabled
										name="label"
                                        type="text"
                                        value={deviceProp.name}
									/>
								</div>
							</div>
                            </div>
                        </div>
                    </div>
                


        
                    <div className="field">
                        <div className="column">
                            <div className="columns">
                                <div className="column is-4">
                                    <label className="label has-help">API Key</label>
                                    <small>The API key for your device - used by your device to communicate with the platform</small>
                                </div>

                                <div className="column">
								<div className="control">
									<textarea
	className="textarea"
	placeholder=""
	disabled
	name="label"
	type="text"
	style={{minHeight: 80}}
	value={device.apikey}
	/>
								</div>
							</div>
                            </div>
                        </div>
                    </div>
                

			
			</Modal>
		)
	}
}

ViewDeviceDetailsModal.propTypes = {
	onClose: PropTypes.func.isRequired,
    device : PropTypes.object.isRequired,
    editable : PropTypes.bool
};

ViewDeviceDetailsModal.defaultProps = {
    onClose : () => {},
    editable : false
};

export default ViewDeviceDetailsModal;
